import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SystemAccount } from '../models/user.model';
import { API_BASE_URL } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string, remember: boolean) {
		try{
            return this.http.post<any>(`${API_BASE_URL}/login`, { email, password, remember })
            .pipe(map(user => {
                //console.log(user)

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                if(user.success){
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;    
            })
        );
        }
        catch(err){
            console.log(err);
        }
        
    }
    
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('rememberLogin');
        this.currentUserSubject.next(null);
    }

    isLogged(){
        return localStorage.getItem('currentUser') != null;
    }
	
	verifyToken(){
        return this.http.get<any>(`${API_BASE_URL}/systemaccount`);
    }
}