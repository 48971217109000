import { Component, OnInit, Inject, Input } from '@angular/core';
import { SystemAccount } from '../models/user.model';
import { AuthenticationService } from '../services/authentication.service';
import { Userservice } from '../services/user.service';
import { Exchangeservice } from '../services/exchange.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { NotifierService } from 'angular-notifier';
import { Generalservice } from '../services/general.service';
import { FormBuilder, FormGroup } from  '@angular/forms';
import { Subscription, Observable, of } from 'rxjs';
import { HttpClientModule, HttpClient, HttpEventType, HttpErrorResponse } from "@angular/common/http";
import { finalize, map, catchError } from 'rxjs/operators';
import { API_URL, API_BASE_URL, BASE_URL, PROFILE_PATH, PROFILE_DEFAULT, Rates } from "../../environments/environment";
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { ResetpasswordComponent } from '../profile/forms/resetpassword/resetpassword.component';
import { LeaftletPathComponent } from '../shared/leaftlet-path/leaftlet-path.component';


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
    userProfile: SystemAccount;
    loggedInfo: any;
    public resetModal: NgbModal;
    public updateInfoModal: NgbModal;
    closeable: Boolean = false;
    notifier: NotifierService;
    formAvatar: FormGroup;
    error: string;
	requirements: any;
	userLangs: any;
	userCertifications: any;
	
	term: any;
	
    inProgress = false;
    completed = false;
    fileProgress = 0;
    @Input() storeUrl: string;
    fileName: string;
    sub: Subscription;
    image: string | SafeUrl = "";
	rates: any;
	providerInfo: any;
	
	req1:any [];
	req2:any [];
	req3:any [];
	req4:any [];
	req5:any [];
	req6:any [];
	req7:any [];
	customStyle: any;
    mySubscription: any;
	
	constructor(private sanitizer: DomSanitizer, private router: Router, private fb: FormBuilder, private exchangeSrv: Exchangeservice, notifierSrv: NotifierService,  private authenticationService: AuthenticationService, private gralSrv: Generalservice, private usrSrv: Userservice, private config: NgbModalConfig, private modalService: NgbModal, @Inject(DOCUMENT) private _document: Document) { 
       this.resetModal = modalService;
       this.updateInfoModal = modalService;
       this.userProfile = {}; 
       this.notifier = notifierSrv; 
	   this.rates = Rates;
	   this.req1 = [];
	   this.req2 = [];
	   this.req3 = [];
	   this.req4 = [];
	   this.req5 = [];
	   this.req6 = [];
	   this.req7 = [];
	   
	   config.backdrop = 'static';
	   config.keyboard = false;

	   //Reload Route and reload data
		this.router.routeReuseStrategy.shouldReuseRoute = function () {
		  return false;
		};
		this.mySubscription = this.router.events.subscribe((event) => {
		  if (event instanceof NavigationEnd) {
			// Trick the Router into believing it's last link wasn't previously loaded
			this.router.navigated = false;
		  }
		});
    }

    ngOnInit() {
	  
	  //Avatar 
      this.formAvatar = this.fb.group({
        avatar: ['']
      });
	  
	  this.customStyle = {
		backgroundColor: "#5e72e4 ",
		border: "5px solid #fff",
		borderRadius: "50%",
		color: "#fff",
		cursor: "pointer",
		position:"absolute",
		width:"190px",
		heigth:"0px",
		left:"50px",
		top: "-80px",
	  };
	  
	  this.loggedInfo = this.authenticationService.currentUserValue['data'];
	  this.reloadUserInfo();
	  if(this.loggedInfo.userGroup == 'PRO' ){
		  this.readProviderInfo();
		  this.readUserRequirements(this.loggedInfo);
		  this.readProviderLangs();
		  this.readUserCertifications(this.loggedInfo);
		}
	}

	ngOnDestroy() {
	  if (this.mySubscription) {
		this.mySubscription.unsubscribe();
	  }
	}
	
    public reloadUserInfo(){
		if(this.loggedInfo.userGroup == 'PRO'){
			this.usrSrv.getProviderByEmail(this.loggedInfo).subscribe((response)=>{
				this.userProfile = response.data;
				this.usrSrv.getUserPhoto(response.data).subscribe((res)=>{
					this.image = res;
				});
			});     
		}else if(this.loggedInfo.userGroup == 'CLI'){
			this.usrSrv.getClientByEmail(this.loggedInfo).subscribe((response)=>{
				this.userProfile = response.data;
				console.log(this.userProfile);
				this.usrSrv.getUserPhoto(response.data).subscribe((res)=>{
					this.image = res;
				});
			});   
		}
	  
    }
	
	public readProviderInfo(){
		this.usrSrv.getProviderInfo(this.loggedInfo).subscribe((res)=>{
			//console.log(res.data);
			this.providerInfo = res.data;
			
		});
	}
	
	public readUserCertifications(user){
		this.usrSrv.getCertificationByUser(user).subscribe(response=>{
			this.userCertifications = response.data;
		});	
	}
	
	public readProviderLangs(){
		this.usrSrv.getLanguageByUser(this.loggedInfo).subscribe((res)=>{
			this.userLangs = res.data;
			//console.log(this.userLangs);
		});
	}
	
	public readUserRequirements(user){
		this.usrSrv.getRequirementByUser(user).subscribe(response=>{
			this.requirements = response.data;
				for(let j=0; j < this.requirements.length; j++){
					if(this.requirements[j].day == 1){
						this.req1.push(this.requirements[j]); 	
					}
					if(this.requirements[j].day == 2){
						this.req2.push(this.requirements[j]); 	
					}
					if(this.requirements[j].day == 3){
						this.req3.push(this.requirements[j]); 	
					}
					if(this.requirements[j].day == 4){
						this.req4.push(this.requirements[j]); 	
					}
					if(this.requirements[j].day == 5){
						this.req5.push(this.requirements[j]); 	
					}
					if(this.requirements[j].day == 6){
						this.req6.push(this.requirements[j]); 	
					}
					if(this.requirements[j].day == 7){
						this.req7.push(this.requirements[j]); 	
					}
			}
			//console.log(this.req1);
		});
		this.usrSrv.getProviderInfo(user).subscribe((res)=>{
			this.providerInfo = res.data;
		});	
		
	}
	
    resetPasswordModal(content) {
        // this.resetModal.open(content, { windowClass: 'modal-medium animate__animated animate__fadeIn', size: 'sm', backdrop: false, centered: true }).result.then((result) => {
        this.resetModal.open(content);
    }

    updateModal(content) {
      this.updateInfoModal.open(content, { windowClass: 'modal-large animate__animated animate__fadeInDown', size: 'lg', backdrop: false, centered: true }).result.then((result) => {
        //alert(`Closed with: ${result}`);
      }, (reason) => {
        //alert('Ok');
      });
  }

    public closeModal() {
		
        this.modalService.dismissAll();
		this.router.navigate(['profile']);
    }

    getExtension(filename): string {
      return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
    }

    fileChangeEvent(event: any): void {
      const fr: File = event.target.files[0];
      const ext = this.getExtension(event.target.files[0].name);
      const f = new File([fr.slice(0, fr.size, fr.type)], 'user' + this.userProfile.systemaccountId + '.' + ext, {type: fr.type});
      this.uploadFile(f);
      this.image = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(f)
      );
	  //Cambiar el avatar barra
	  this.exchangeSrv.setfileUp(f);	
	  //Update user avatar info
	  var data = {
			  "systemaccountId":this.userProfile.systemaccountId,
			  "photo": f.name,
		  };
	  this.usrSrv.updateUserPhoto(data).subscribe((res)=>{
		  //console.log(res);
		if(res.success == 1){
			//alert(res.message);
          this.notifier.notify('success', res.message);
		  this.router.navigate(['profile']);
        }else{
          this.notifier.notify('error', res.message);
        }
        //this.isLoading = false;
	  });
    }
  

    uploadFile(file): void {
      this.inProgress = true;
      this.sub = this.gralSrv.uploadFileProgress(PROFILE_PATH, file).pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.fileProgress = Math.round(event.loaded * 100 / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.inProgress = false;
          this.notifier.notify('Error uploading file...', 'Error');
          return of(`Carga de archivo: fallida.`);
        })).subscribe(
          (event: any) => {
            if (typeof (event) === 'object') {
              this.notifier.notify('Carga completada', 'Error');
              this.completed = true;
              this.inProgress = false;
              this.fileName = file.name;
            }
          },
          (error) => console.log(error),
          () => console.log('completado')
        );
    }
    
 
  onSubmit() {
 
  }
}
