import {Marker, LatLng, MarkerOptions} from 'leaflet';

export class MarkerExt extends Marker{
	county: any = "";
	city: any = "";
	state:any = "";
	selected:any = false;
	latitude: any;
	longitude: any;

	options: MarkerOptions;

    constructor(latitude: number, longitude:number, options?: MarkerOptions) {
        super([latitude, longitude], options);
		this.latitude = latitude;
		this.longitude = longitude;
    }
	
	greet(): this {
        return this
    }
}