import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common'; 

import { Languages, CertificationType, ProviderActivities, Days, API_BASE_URL, Rates} from '../../../../environments/environment';
import { Userservice } from '../../../services/user.service';
import { AuthenticationService } from '../../../services/authentication.service';
//import { LeaftletPathComponent } from '../../../shared/leaftlet-path/leaftlet-path.component';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import  Swal  from 'sweetalert2';
import { NotifierService } from 'angular-notifier';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-providerinfotabs',
  templateUrl: './providerinfotabs.component.html',
  styleUrls: ['./providerinfotabs.component.css']
})
export class ProviderinfotabsComponent implements OnInit {	
	
  languages: {};
  certTypes: {};
  activities: {};
  requirements: any;
  userLangs: any;
  userCertifications: any;
  userTitulations: any;
  userProcedings: any;
  loggedInfo: any;
  dtOptions: any;
  days: any;
  selectedDay: any;
  dayList:any = [];
  langList:any = [];
  scheduleForm: FormGroup;
  certificationForm: FormGroup;
  titulationForm: FormGroup;
  procedingForm: FormGroup;
  notifier: any;
  error: any;
  mostrar = false;
  selectedRate: any;
  rates: any;
  isLoading: boolean;
  languageSelected: any;
  
  constructor(@Inject(DOCUMENT) document, private usrSrv: Userservice, private authenticationService: AuthenticationService, public fb: FormBuilder, notifierSrv: NotifierService) { 
    this.languages = Languages;
	this.certTypes = CertificationType;
	this.activities = ProviderActivities;
	this.notifier = notifierSrv;
  }
  
	public readUserRequirements(user){
		this.usrSrv.getRequirementByUser(user).subscribe(response=>{
			this.requirements = response.data;
		});	
	}	
	
	public readUserLangs(user){
		this.langList = [];
		this.usrSrv.getLanguageByUser(user).subscribe(response=>{
			this.userLangs = response.data;
			if(this.userLangs == 'undefined') return;
			for(let l of this.userLangs){
				this.langList.push(l);
				var check = document.getElementById(l.languageId) as HTMLInputElement;
				if(check) check.checked = true;
			}
		});	
	}
	
	public readUserCertifications(user){
		this.usrSrv.getCertificationByUser(user).subscribe(response=>{
			this.userCertifications = response.data;
		});	
	}
	
	public readUserTitulations(user){
		this.usrSrv.getTitulationByUser(user).subscribe(response=>{
			this.userTitulations = response.data;
		});	
	}
	
	public readUserProcedings(user){
		this.usrSrv.getProcedingByUser(user).subscribe(response=>{
			this.userProcedings = response.data;
		});	
	}
	
  ngOnInit(): void {
	
	//Data table
	this.loggedInfo = this.authenticationService.currentUserValue['data'];
	this.days = Days;
	this.rates = Rates;
		   
	if(this.loggedInfo.userGroup == 'PRO'){	   
		this.readUserRequirements(this.loggedInfo);
		this.readUserCertifications(this.loggedInfo);
		this.readUserTitulations(this.loggedInfo);
		this.readUserProcedings(this.loggedInfo);
		this.readUserLangs(this.loggedInfo);
	}
	this.dtOptions = {
		select: true,
		responsive: true,
		searching: false,
		lengthChange: false
    };
	
	this.scheduleForm = this.fb.group({
		  chk1: ['',[]],    
		  chk2: ['', []],
		  chk3: ['', []],
		  chk4: ['', []],
		  chk5: ['', []],    
		  chk6: ['', []],    
		  chk7: ['', []],   
		  startTime: ['', [Validators.required]],   
		  endTime: ['', [Validators.required]],   
		});
	
	this.certificationForm = this.fb.group({
		  certificationNo: ['',[Validators.required]],    
		  language: ['', [Validators.required]],
		  type: ['', [Validators.required]],
		  expiration: ['', [Validators.required]],
		  expirationRegister: ['', []]
	});	
	
	this.titulationForm = this.fb.group({
		  titulation: ['',[Validators.required]],    
		  acreditationDate: ['', []]
	});	
	
	this.procedingForm = this.fb.group({
		  activity: ['',[Validators.required]],    
		  price: ['', [Validators.required]]
	});	
}
    

	
	// convenience getter for easy access to form fields
	get f() { return this.scheduleForm.controls; }
  
  
  onSubmit() {
	  //console.log(this.loggedInfo);
      // stop here if form is invalid
      if (this.scheduleForm.invalid ) {
          return;
      }
      //this.loading = true;
	  var frmData = {
		  "systemaccountId": this.loggedInfo.systemaccountId,
		  "days": this.dayList,
		  "startTime": this.f.startTime.value,   
		  "endTime":   this.f.endTime.value,
		  "available": true
	  };
	  
      this.usrSrv.createRequirement(frmData).subscribe((data) => {
        //console.log(data);
        if(data.success){
			this.readUserRequirements(this.loggedInfo);	
			this.notifier.notify("success", data.message);  
			this.scheduleForm.reset();
			this.mostrar = false;
			this.dayList.splice(0,this.dayList.length)
        }
        else{
          this.notifier.notify("error", data.message);  
        }
        //this.loading = false;  
      },
      error => {
        this.error = error;
        //this.loading = false;
        this.notifier.notify("error", error.message);
      });
    }


	saveCertification(){
		this.isLoading = true;
		var data = {
			"systemaccountId": this.loggedInfo.systemaccountId,
			"certificationNo": this.certificationForm.controls.certificationNo.value,
			"language": this.certificationForm.controls.language.value.name,
			"code": this.certificationForm.controls.language.value.code,
			"type": this.certificationForm.controls.type.value.type,
			"expiration": this.certificationForm.controls.expiration.value,
			"expirationRegister": this.certificationForm.controls.expirationRegister.value
		};
		this.usrSrv.createCertification(data).subscribe((response) => {
			//console.log(response);
			if(response.success){
				this.notifier.notify("success", response.message);
				this.isLoading = false;
				this.certificationForm.reset();
				this.readUserCertifications(this.loggedInfo);
			}
			else{
			  this.notifier.notify("error", response.message);  
			  this.isLoading = false;  
			}
		});	
	}
	
	saveTitulation(){
		this.isLoading = true;
		var data = {
			"systemaccountId": this.loggedInfo.systemaccountId,
			"titulation": this.titulationForm.controls.titulation.value,
			"acreditationDate": this.titulationForm.controls.acreditationDate.value
		};
		this.usrSrv.createTitulation(data).subscribe((response) => {
			if(response.success){
				this.notifier.notify("success", response.message);
				this.isLoading = false;
				this.titulationForm.reset();
				this.readUserTitulations(this.loggedInfo);
			}
			else{
			  this.notifier.notify("error", response.message);  
			  this.isLoading = false;  
			}
		});	
	}
	
	saveProceding(){
		this.isLoading = true;
		var data = {
			"systemaccountId": this.loggedInfo.systemaccountId,
			"activity": this.procedingForm.controls.activity.value,
			"price": this.procedingForm.controls.price.value
		};
		this.usrSrv.createProceding(data).subscribe((response) => {
			if(response.success){
				this.notifier.notify("success", response.message);
				this.isLoading = false;
				this.procedingForm.reset();
				this.readUserProcedings(this.loggedInfo);
			}
			else{
			  this.notifier.notify("error", response.message);  
			  this.isLoading = false;  
			}
		});
		
		
	}
	
	onAddLang(e, l):void{
		//console.log(this.langList.length);
		if (e.target.checked) {
			this.langList.push(l);
		} 
		else {
			this.langList.forEach((value,index)=>{
				if(value.languageId == l.languageId) {
					this.langList.splice(index, 1);
				}
			});
		}
	}
	
	onSelectLang(){
		this.isLoading = true; 
		var data = {
			"langs": this.langList,
			"systemaccountId": this.loggedInfo.systemaccountId,
		};
		if(this.langList.length <= 0){
			this.notifier.notify("error", "Select language");
			return;
		}	
		
			
		this.usrSrv.createLanguage(data).subscribe((response) => {
	//        console.log(data);
			if(response.success){
				this.notifier.notify("success", response.message);
				this.isLoading = false;  		
				this.readUserLangs(this.loggedInfo);	
				//this.mostrar = false;
				//this.langList.splice(0,this.dayList.length)
			}
			else{
			  this.notifier.notify("error", response.message);  
			  this.isLoading = false;  
			}
			
		  },
		  error => {
			this.error = error;
			this.isLoading = false;
			this.notifier.notify("error", error.message);
		  });  
	}
  
  onChange($value):void{
	  //console.log($value); 
	  this.selectedDay = $value;  
	  //alert(this.selectedDay);
  }
  
  onAddDay(e):void{
	if (e.target.checked) {
      this.dayList.push(e.target.value);
    } 
	else {
		const index = this.dayList.indexOf(e.target.value);
		if (index !== -1) {
			this.dayList.splice(index, 1);
		} 
	}
	//console.log(this.dayList);
  }
  
  
  onChangeRate($event){
	 //alert($event); 
	 var rateStr = $event;
	 var ratesArr = [];
	 var rateMin, rateMax;
	 ratesArr = rateStr.split('-', 2);
	 
	if(ratesArr.length <= 0){
		this.notifier.notify("Error", "Select your rate");
		return;
	}	
	
	 var data = {
		"rateMin": ratesArr[0],
		"rateMax": ratesArr[1],
		"systemaccountId": this.loggedInfo.systemaccountId
	 };
	
	
	this.usrSrv.updateProviderRate(data).subscribe(response=>{
		if(response.success){
			this.notifier.notify("success", response.message);  
		}
        else{
          this.notifier.notify("error", response.message);  
        }
        //this.loading = false;  
     }, error => {
        this.error = error;
        this.notifier.notify("error", error.message);
      });		
  }
  
  onDelete(req){
	Swal.fire({
	  title: 'Are you sure?',
	  text: "You won't be able to revert this!",
	  icon: 'warning',
	  showCancelButton: true,
	  confirmButtonColor: '#3085d6',
	  cancelButtonColor: '#d33',
	  confirmButtonText: 'Yes, delete it!'
	}).then((result) => {
	  if (result.isConfirmed) {
		this.usrSrv.deleteRequirement(req).subscribe(response=>{
		if(response.success){
			this.notifier.notify("success", response.message); 
			this.readUserRequirements(this.loggedInfo);			
		}
		else{
          this.notifier.notify("error", response.message);  
        }
		},error => {
			this.error = error;
			this.notifier.notify("error", error.message);
		});
	  }
	})
	
  }
  
  onDeleteCert(cert){
	  //console.log(cert);
	Swal.fire({
	  title: 'Are you sure?',
	  text: "You won't be able to revert this!",
	  icon: 'warning',
	  showCancelButton: true,
	  confirmButtonColor: '#3085d6',
	  cancelButtonColor: '#d33',
	  confirmButtonText: 'Yes, delete it!'
	}).then((result) => {
	  if (result.isConfirmed) {
		this.usrSrv.deleteCertification(cert).subscribe(response=>{
		if(response.success){
			this.notifier.notify("success", response.message); 
			this.readUserCertifications(this.loggedInfo);			
		}
		else{
          this.notifier.notify("error", response.message);  
        }
		},error => {
			this.error = error;
			this.notifier.notify("error", error.message);
		});
	  }
	})
	
  }
  
  onDeleteTitulation(tit){
	  //console.log(cert);
	Swal.fire({
	  title: 'Are you sure?',
	  text: "You won't be able to revert this!",
	  icon: 'warning',
	  showCancelButton: true,
	  confirmButtonColor: '#3085d6',
	  cancelButtonColor: '#d33',
	  confirmButtonText: 'Yes, delete it!'
	}).then((result) => {
	  if (result.isConfirmed) {
		this.usrSrv.deleteTitulation(tit).subscribe(response=>{
		if(response.success){
			this.notifier.notify("success", response.message); 
			this.readUserTitulations(this.loggedInfo);			
		}
		else{
          this.notifier.notify("error", response.message);  
        }
		},error => {
			this.error = error;
			this.notifier.notify("error", error.message);
		});
	  }
	})
	
  }
  
  onDeleteProceding(proc){
	  //console.log(cert);
	Swal.fire({
	  title: 'Are you sure?',
	  text: "You won't be able to revert this!",
	  icon: 'warning',
	  showCancelButton: true,
	  confirmButtonColor: '#3085d6',
	  cancelButtonColor: '#d33',
	  confirmButtonText: 'Yes, delete it!'
	}).then((result) => {
	  if (result.isConfirmed) {
		this.usrSrv.deleteProceding(proc).subscribe(response=>{
			//console.log(proc);
		if(response.success){
			this.notifier.notify("success", response.message); 
			this.readUserProcedings(this.loggedInfo);			
		}
		else{
          this.notifier.notify("error", response.message);  
        }
		},error => {
			this.error = error;
			this.notifier.notify("error", error.message);
		});
	  }
	})
  }
}
