import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common'; 

import { Languages, ClientActivities, API_BASE_URL, Providers} from '../../../../environments/environment';
import { Userservice } from '../../../services/user.service';
import { AuthenticationService } from '../../../services/authentication.service';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import  Swal  from 'sweetalert2';
import { NotifierService } from 'angular-notifier';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-clientinfotabs',
  templateUrl: './clientinfotabs.component.html',
  styleUrls: ['./clientinfotabs.component.css']
})
export class ClientinfotabsComponent implements OnInit {
  activities: {};
  loggedInfo: any;
  mostrar = false;
  isLoading: boolean;
  error: any;
  notifier: any;
  areaList:any = [];
  clientAreas: any;	
  provList: any = [];
  clientProv: any;
  providers:any;
  
  constructor(private usrSrv: Userservice, private authenticationService: AuthenticationService, public fb: FormBuilder, notifierSrv: NotifierService) { 
	this.activities = ClientActivities;
	this.providers = Providers;
	this.notifier = notifierSrv;
	

  }

  ngOnInit(): void {
	  this.loggedInfo = this.authenticationService.currentUserValue['data'];
	  if(this.loggedInfo.userGroup == 'CLI'){	   
		this.readClientAreaPractice(this.loggedInfo);
	}
  }
  
  public readClientAreaPractice(user){
		this.areaList = [];
		this.usrSrv.getAreasPracticeByUser(user).subscribe(response=>{
			this.clientAreas = response.data;
			if(this.clientAreas == 'undefined') return;
			for(let l of this.clientAreas){
				//console.log(l);
				this.areaList.push(l);
				var check = document.getElementById(l.areaId) as HTMLInputElement;
				if(check) check.checked = true;
			}
		});	
	}
  
  onAddAct(e, a):void{
		//console.log(this.areaList.length);
		if (e.target.checked) {
			this.areaList.push(a);
		} 
		else {
			this.areaList.forEach((value,index)=>{
				if(value.areaId == a.areaId) {
					this.areaList.splice(index, 1);
				}
			});
		}
		//console.log(this.areaList);
	}
	
	onSaveAreasPractice(){
		this.isLoading = true; 
		var data = {
			"area": this.areaList,
			"systemaccountId": this.loggedInfo.systemaccountId,
		};
		if(this.areaList.length <= 0){
			this.notifier.notify("error", "Select Area of Practice");
			return;
		}	
		
			
		this.usrSrv.createAreasPractice(data).subscribe((response) => {
	//        console.log(data);
			if(response.success){
				this.notifier.notify("success", response.message);
				this.isLoading = false;  		
				this.readClientAreaPractice(this.loggedInfo);	
			}
			else{
			  this.notifier.notify("error", response.message);  
			  this.isLoading = false;  
			}
			
		  },
		  error => {
			this.error = error;
			this.isLoading = false;
			this.notifier.notify("error", error.message);
		  });  
	}
	
	onAddPrefProvider(e, a):void{
		//console.log(this.areaList.length);
		if (e.target.checked) {
			this.provList.push(a);
		} 
		else {
			this.provList.forEach((value,index)=>{
				if(value.val == a.val) {
					this.provList.splice(index, 1);
				}
			});
		}
		console.log(this.areaList);
	}

}
