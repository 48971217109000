import { Component, OnInit } from "@angular/core";
import { NgbModalConfig, NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Userservice } from "../services/user.service";
import { Observable } from "rxjs";

import { Providers, Clients } from "../../environments/environment";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
  providers: [NgbModalConfig, NgbModal]
})
export class SignupComponent implements OnInit {
  UserList: any[];
  public theModal: NgbModal;
  closeable: Boolean = false;
  clients: any;
  providers: any;
  cover: any;

  constructor(config: NgbModalConfig, private modalObj: NgbModal, private userSrv: Userservice) {
    //config.backdrop = 'static';
    //config.keyboard = false;
    config.windowClass = "modal-large";
    config.size = "lg";
    //config.centered = true;

    this.theModal = modalObj;
    this.clients = Clients;
    this.providers = Providers;
  }

  ngOnInit() {}

  registerModal(content) {
    this.theModal
      .open(content).result.then(result => {
          //alert(`Closed with: ${result}`);
        },
        reason => {
          //alert(`Dismissed ${this.getDismissReason(reason)}`);
        }
      );
  }

  public closeModal() {
    this.modalObj.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
