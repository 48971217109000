import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { HomeComponent } from './home.component';
import { SectionsModule } from '../sections/sections.module';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule,
        SectionsModule,
        NgbModule,
    ],
    declarations: [ HomeComponent,],
    exports:[ HomeComponent,  ],
    providers: []
})
export class HomeModule { }
