import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClientModule, HttpClient, HttpEvent, HttpErrorResponse, HttpEventType  } from "@angular/common/http";
import { API_URL, API_BASE_URL, BASE_URL } from "../../environments/environment";
import { Observable } from 'rxjs';
import { finalize, map, catchError } from 'rxjs/operators';
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class Exchangeservice {
	//Attributes
	 @Output() change: EventEmitter<any> = new EventEmitter();
	
  constructor(private sanitizer: DomSanitizer) { }
	fileUp: any;
	//Methods
	public setfileUp(file){
		this.fileUp = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
		this.change.emit(this.fileUp);
	}
   
}
