import { Component, OnInit, Input } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PATTERN } from 'src/environments/environment';
import { ConfirmedValidator } from 'src/app/directives/passwordConfirmValidator';
import { ProfileComponent } from '../../profile.component';
import { Userservice } from '../../../services/user.service';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  resetForm: FormGroup;
  private notifier: NotifierService;
  isLoading: boolean;
  theModal:any;
  currentUser: any;
  
  constructor(notifierSrv: NotifierService, public fb: FormBuilder, public profileParent: ProfileComponent, private usrSrv: Userservice, private authSrv: AuthenticationService) {
    this.isLoading = false;
    this.notifier = notifierSrv; 
    //this.currentUser = authSrv.currentUser;
   }

  ngOnInit(): void {
    this.currentUser = this.authSrv.currentUserValue['data'];
    this.resetForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(PATTERN.password)]],
      retypePassword: ['',[Validators.required]]      
    }, 
    { 
      validator: ConfirmedValidator('password', 'retypePassword')
    });
  }

    //Evento cerrar Dialogo
    closeThis(): void {
      this.resetForm.reset();
      this.profileParent.closeModal();
    }

    resetPassword(){
      this.isLoading = true;
      this.currentUser.password =  this.resetForm.controls.password.value;
      //console.log(this.currentUser);
      this.usrSrv.resetPassword(this.currentUser).subscribe((response)=>{
        if(response.success){
          this.notifier.notify('success', response.message);
        }else{
          this.notifier.notify('warning', response.message);
        }
        this.isLoading = false;
        this.resetForm.reset();
        this.closeThis();
      }, (error)=>{
        this.notifier.notify('error', error.message);
        this.isLoading = false;
      });

    }


}
