export const timeDiff = (timeEnd: any, timeInit:any) =>{
	// // Calcula los minutos de cada hora
	var minutos_inicio = timeInit.split(':').reduce((p:any, c:any) => parseInt(p) * 60 + parseInt(c));
	var minutos_final = timeEnd.split(':').reduce((p:any, c:any) => parseInt(p) * 60 + parseInt(c));
	console.log(minutos_inicio)
	console.log(minutos_final)

	// // Si la hora final es anterior a la hora inicial sale
	if (minutos_final < minutos_inicio) return null;

	// // Diferencia de minutos
	 var diferencia = minutos_final - minutos_inicio;
	 
	// // Cálculo de horas y minutos de la diferencia
	 var horas = Math.floor(diferencia / 60 / 60);
	 var minutos = diferencia % 60;

	 return {
	  difMin: diferencia,
	  horas: horas,
	  minutos: minutos,
	};
  };