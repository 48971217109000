import { Component, OnInit } from '@angular/core';
import { ProfileComponent } from '../../profile.component';

@Component({
  selector: 'app-editclientinfo',
  templateUrl: './editclientinfo.component.html',
  styleUrls: ['./editclientinfo.component.css']
})
export class EditclientinfoComponent implements OnInit {

  constructor(public profileParent: ProfileComponent) { }

  ngOnInit(): void {
  }

  
    closeThis(): void {
        this.profileParent.closeModal();
	}
}
