import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProfileComponent } from '../../profile.component';
import { Userservice } from '../../../services/user.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { Generalservice } from 'src/app/services/general.service';
import { ResetpasswordComponent } from '../resetpassword/resetpassword.component';


@Component({
  selector: 'app-edituserinfo',
  templateUrl: './edituserinfo.component.html',
  styleUrls: ['./edituserinfo.component.css']
})
export class EdituserinfoComponent implements OnInit {
  updateForm: FormGroup;
  notifier: NotifierService;
  isLoading: boolean;
  theModal:any;
  currentUser: any;
  currentJustify = 'fill'; // justified, start, center, end, fill
  countries: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(notifierSrv: NotifierService, public fb: FormBuilder, public profileParent: ProfileComponent, private usrSrv: Userservice, private generalSrv: Generalservice, private authSrv: AuthenticationService) {
    this.isLoading = false;
    this.notifier = notifierSrv; 
    this.currentUser = this.authSrv.currentUserValue;
   }

  ngOnInit(): void {
    //this.currentUser = this.authSrv.currentUserValue['data'];
		this.updateForm = this.fb.group({
		  name: ['',[Validators.required]],    
		  lastname: ['', [Validators.required]],
		  phone: ['', []],
		  address: ['', [Validators.required]],
		  countryId: ['', [Validators.required]],    
		  state: ['', [Validators.required]],    
		  county: ['', [Validators.required]],   
		  zip: ['', []],   
		  mobile: ['', [Validators.required]],   
		  website: ['', []],   
		  fax: ['', []],  
		  taxID: ['', []],  
		  mailingAddress: ['', []],  
		  
		});

    /*this.generalSrv.getPaises().subscribe((response)=>{
      this.countries = response.data;
      this.loadUserData(); 
      
    });*/

	this.loadUserData(); 
    
  }
    //Evento cerrar Dialogo
    closeThis(): void {
        this.updateForm.reset();
        this.profileParent.closeModal();
	}

  updateUserInfo(){
    this.isLoading = true;
    var tempData = this.updateForm.value;
    tempData.systemaccountId = this.currentUser.data.systemaccountId;
    
    this.usrSrv.updateUserInfo(tempData).subscribe((response)=>{
		//console.log(response);
      if(response.success){
        //this.profileParent.reloadUserInfo();
        this.notifier.notify('success', response.message);
      }else{
        this.notifier.notify('warning', response.message);
      }
      this.isLoading = false;
     
      this.closeThis();
    }, (error)=>{
      this.notifier.notify('error', error.message);
      this.isLoading = false;
    });
  }

  loadUserData(){
	  if(this.currentUser.data.userGroup == 'PRO'){
		this.usrSrv.getProviderByEmail(this.currentUser.data).subscribe((response)=>{
		  var data = {
			"name": response.data.name,
			"lastname": response.data.lastname,
			"phone": response.data.phone,
			"address": response.data.address,
			"state": response.data.state,
			"county": response.data.county,
			"countryId": 1,
			"mobile": response.data.mobile,
			"zip": response.data.zip,
			"website": response.data.website,
			"fax": response.data.fax,
			"taxID": response.data.taxID,
			"mailingAddress": response.data.mailingAddress
		  }
		  this.updateForm.setValue(data);
		});  
	  }else{
		  //console.log(this.currentUser.data);
		  this.usrSrv.getClientByEmail(this.currentUser.data).subscribe((response)=>{
			  console.log(response);
		  var data = {
			"name": response.data.name,
			"lastname": response.data.lastname,
			"phone": response.data.phone,
			"address": response.data.address,
			"state": response.data.state,
			"county": response.data.county,
			"countryId": 1,
			"mobile": response.data.mobile,
			"zip": response.data.zip,
			"website": response.data.website,
			"fax": response.data.fax,
			"taxID": "",
			"mailingAddress": ""
		  }
		  this.updateForm.setValue(data);
		});  
	  }
    
  }
}
