import { Injectable } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { API_URL, API_BASE_URL } from "../../environments/environment";
import { Observable } from "rxjs";
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { finalize, map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class Userservice {
  constructor(private sanitizer: DomSanitizer, private http: HttpClient) {}

  //--------------------------
  //USERS
  //--------------------------
  getSystemaccountByUsernameOrEmail(data): Observable<any> {
    return this.http.post(API_BASE_URL + "/systemaccount", data);
  }

  register(data: any): Observable<any> {
    return this.http.post(API_BASE_URL + "/register", data);
  }

  getSystemaccountByEmail(data): Observable<any> {
    return this.http.post(API_BASE_URL + "/systemaccount/getbyemail", data);
  }

  getProviderByEmail(data): Observable<any> {
    return this.http.post(
      API_BASE_URL + "/systemaccount/getProviderbyemail",
      data
    );
  }

  getClientByEmail(data): Observable<any> {
    return this.http.post(
      API_BASE_URL + "/systemaccount/getClientbyemail",
      data
    );
  }

  getSystemaccountByUsername(data): Observable<any> {
    return this.http.post(API_BASE_URL + "/systemaccount/getbyusername", data);
  }

  getSystemaccountByToken(data): Observable<any> {
    return this.http.post(API_BASE_URL + "/systemaccount/getbytoken", data);
  }

  resetPassword(data): Observable<any> {
    return this.http.patch(API_BASE_URL + "/systemaccount/resetPassword", data);
  }

  updateUserInfo(data): Observable<any> {
    return this.http.patch(
      API_BASE_URL + "/systemaccount/updateUserInfo",
      data
    );
  }

  updateProviderRate(data): Observable<any> {
    return this.http.patch(
      API_BASE_URL + "/systemaccount/updateproviderrate",
      data
    );
  }

  activateUser(data): Observable<any> {
    return this.http.patch(API_BASE_URL + "/systemaccount/activateUser", data);
  }

  updateUserPhoto(data): Observable<any> {
    return this.http.patch(
      API_BASE_URL + "/systemaccount/updateUserPhoto",
      data
    );
  }

  getUserPhoto(data): Observable<any> {
    //console.log(data.photo);
    return this.http
      .get(API_BASE_URL + "/systemaccount/avatar/" + data.photo, {
        responseType: "blob"
      })
      .pipe(
        map(e => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e)))
      );
  }

  //--------------------------
  //PROVIDERS
  //--------------------------
  getProviderInfo(data): Observable<any> {
    return this.http.get(
      API_BASE_URL + "/systemaccount/provider/" + data.systemaccountId
    );
  }

  getProviders(): Observable<any> {
    return this.http.get(API_BASE_URL + "/systemaccount/provider/");
  }

  //--------------------------
  //REQUIREMENTS
  //--------------------------
  createRequirement(data: any): Observable<any> {
    return this.http.post(API_BASE_URL + "/requirement", data);
  }

  updateRequirement(data): Observable<any> {
    return this.http.patch(API_BASE_URL + "/requirement/update", data);
  }

  getRequirements(): Observable<any> {
    return this.http.get(API_BASE_URL + "/requirement");
  }

  getRequirement(data): Observable<any> {
    return this.http.get(API_BASE_URL + "/requirement/" + data.requirementId);
  }

  getRequirementByUser(data): Observable<any> {
    //console.log(data);
    return this.http.get(
      API_BASE_URL + "/requirement/getByUser/" + data.systemaccountId
    );
  }

  deleteRequirement(data): Observable<any> {
    return this.http.delete(
      API_BASE_URL + "/requirement/delete/" + data.requirementId
    );
  }

  //--------------------------
  //LANGS
  //--------------------------
  createLanguage(data: any): Observable<any> {
    return this.http.post(API_BASE_URL + "/language", data);
  }

  getLanguages(): Observable<any> {
    return this.http.get(API_BASE_URL + "/language");
  }

  getLanguage(data): Observable<any> {
    return this.http.get(API_BASE_URL + "/language/" + data.languageId);
  }

  getLanguageByUser(data): Observable<any> {
    //console.log(data);
    return this.http.get(
      API_BASE_URL + "/language/getByUser/" + data.systemaccountId
    );
  }

  deleteLanguage(data): Observable<any> {
    return this.http.delete(
      API_BASE_URL + "/language/delete/" + data.languageId
    );
  }

  //--------------------------
  //CERtIFICATIONS
  //--------------------------
  createCertification(data: any): Observable<any> {
    return this.http.post(API_BASE_URL + "/certification", data);
  }

  getCertifications(): Observable<any> {
    return this.http.get(API_BASE_URL + "/certification");
  }

  getCertification(data): Observable<any> {
    return this.http.get(
      API_BASE_URL + "/certification/" + data.certificationId
    );
  }

  getCertificationByUser(data): Observable<any> {
    return this.http.get(
      API_BASE_URL + "/certification/getByUser/" + data.systemaccountId
    );
  }

  deleteCertification(data): Observable<any> {
    return this.http.delete(
      API_BASE_URL + "/certification/delete/" + data.certificationId
    );
  }

  //--------------------------
  //TITULATION
  //--------------------------
  createTitulation(data: any): Observable<any> {
    return this.http.post(API_BASE_URL + "/titulation", data);
  }

  getTitulations(): Observable<any> {
    return this.http.get(API_BASE_URL + "/titulation");
  }

  getTitulation(data): Observable<any> {
    return this.http.get(API_BASE_URL + "/titulation/" + data.titulationId);
  }

  getTitulationByUser(data): Observable<any> {
    return this.http.get(
      API_BASE_URL + "/titulation/getByUser/" + data.systemaccountId
    );
  }

  deleteTitulation(data): Observable<any> {
    return this.http.delete(
      API_BASE_URL + "/titulation/delete/" + data.titulationId
    );
  }

  //--------------------------
  //Procedings to cover
  //--------------------------
  createProceding(data: any): Observable<any> {
    return this.http.post(API_BASE_URL + "/proceding", data);
  }

  getProcedings(): Observable<any> {
    return this.http.get(API_BASE_URL + "/proceding");
  }

  getProceding(data): Observable<any> {
    return this.http.get(API_BASE_URL + "/proceding/" + data.procedingcoverId);
  }

  getProcedingByUser(data): Observable<any> {
    return this.http.get(
      API_BASE_URL + "/proceding/getByUser/" + data.systemaccountId
    );
  }

  getProcedingByOrder(data): Observable<any> {
    return this.http.get(
      API_BASE_URL + "/proceding/getByOrder/" + data.orderId
    );
  }

  deleteProceding(data): Observable<any> {
    return this.http.delete(
      API_BASE_URL + "/proceding/delete/" + data.procedingcoverId
    );
  }

  //--------------------------
  //Areas of Coverage
  //--------------------------
  createArea(data): Observable<any> {
    //console.log(data);
    return this.http.post(API_BASE_URL + "/area", data);
  }

  getAreas(): Observable<any> {
    return this.http.get(API_BASE_URL + "/area");
  }

  getAreasByUser(data): Observable<any> {
    return this.http.get(
      API_BASE_URL + "/area/getByUser/" + data.systemaccountId
    );
  }

  deleteArea(data): Observable<any> {
    return this.http.delete(
      API_BASE_URL + "/area/delete/" + data.areascoverageId
    );
  }

  //--------------------------
  //CLIENTS
  //Areas of Practice
  //--------------------------
  createAreasPractice(data): Observable<any> {
    //console.log(data);
    return this.http.post(API_BASE_URL + "/areapractice", data);
  }

  getAreasPractice(): Observable<any> {
    return this.http.get(API_BASE_URL + "/areapractice");
  }

  getAreasPracticeByUser(data): Observable<any> {
    return this.http.get(
      API_BASE_URL + "/areapractice/getByUser/" + data.systemaccountId
    );
  }

  deleteAreasPractice(data): Observable<any> {
    return this.http.delete(
      API_BASE_URL + "/areapractice/delete/" + data.areaspracticeId
    );
  }

  //--------------------------
  //Orders
  //--------------------------
  createOrder(data: any): Observable<any> {
    return this.http.post(API_BASE_URL + "/order", data);
  }

  createCanceledOrder(data: any): Observable<any> {
    return this.http.post(API_BASE_URL + "/canceledorder", data);
  }

  getOrders(): Observable<any> {
    return this.http.get(API_BASE_URL + "/order");
  }

  getOrder(data): Observable<any> {
    return this.http.get(API_BASE_URL + "/order/" + data.orderId);
  }

  getOrderByUser(data): Observable<any> {
    return this.http.get(
      API_BASE_URL + "/order/getByUser/" + data.systemaccountId
    );
  }

  updateOrderStatus(OrderId: any, status: any) {
    return this.http.patch( API_BASE_URL + "/order/updatestatus/", {
        orderId: OrderId,
        status: status,
      });
  }

  updateOrderPdfPath(OrderId: any, pdfpath: any) {
    return this.http.patch( API_BASE_URL + "/order/updatepdfpath/", {
        orderId: OrderId,
        pdfpath: pdfpath,
      });
  }

  deleteOrder(data): Observable<any> {
    return this.http.delete(
      API_BASE_URL + "/order/delete/" + data.areaspracticeId
    );
  }

  //--------------------------
  //Offices
  //--------------------------
  createClientOffice(data: any): Observable<any> {
    //console.log(data);
    return this.http.post(API_BASE_URL + "/clientoffice", data);
  }
  getOfficesAll(): Observable<any> {
    return this.http.get(API_BASE_URL + "/clientoffice");
  }
  useOffices(data: any): Observable<any> {
    console.log(data);
    return this.http.post(API_BASE_URL + "/clientoffice/useclientoffice/", data);
  }
  getClientOfficeByUser (systemaccountId): Observable<any> {
    return this.http.get(API_BASE_URL + "/clientoffice/getByUser/" + systemaccountId);
  }
  getClientOfficeUse(clientOfficeId): Observable<any> {
    return this.http.get(API_BASE_URL + "/clientoffice/getuse/" + clientOfficeId);
  }
  deleteClientOffice(id): Observable<any> {
    return this.http.delete(API_BASE_URL + "/clientoffice/delete/" + id);
  }

  deleteOffice(id): Observable<any> {
    return this.http.delete(API_BASE_URL + "/clientoffice/deleteoffice/" + id);
  }
}
