import { BrowserModule,HammerModule  } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { DataTablesModule } from 'angular-datatables';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
//import { RatingModule } from 'ng-starrating';

//import { PipesModule } from 'w-ng5';
//import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
//import { LandingComponent } from './landing/landing.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { NavbarBackofficeComponent } from './shared/navbar-backoffice/navbar-backoffice.component';
import { FooterComponent } from './shared/footer/footer.component';

import { HomeModule } from './home/home.module';
import { LoginComponent } from './login/login.component';
//import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignupProviderComponent } from './signup/forms/signup-provider/signup-provider.component';
import { SignupClientComponent } from './signup/forms/signup-client/signup-client.component';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from '../app/helpers/error.interceptor';
import { JwtInterceptor } from '../app/helpers/jwt.interceptor';
import { ContactComponent } from './contact/contact.component';

import { EdituserinfoComponent } from './profile/forms/edituserinfo/edituserinfo.component';
import { ResetpasswordComponent } from './profile/forms/resetpassword/resetpassword.component';
import { EditproviderinfoComponent } from './profile/forms/editproviderinfo/editproviderinfo.component';
import { EditclientinfoComponent } from './profile/forms/editclientinfo/editclientinfo.component';
import { ProviderinfotabsComponent } from './profile/forms/providerinfotabs/providerinfotabs.component';
import { ClientinfotabsComponent } from './profile/forms/clientinfotabs/clientinfotabs.component';
import { ProfileComponent } from './profile/profile.component';
import { ProviderbillinginfoComponent } from './profile/forms/providerbillinginfo/providerbillinginfo.component';
import { LeaftletPathComponent } from './shared/leaftlet-path/leaftlet-path.component';
import { OrdersComponent } from './orders/orders.component';
import { TestComponent } from './test/test.component';
import { OfficesComponent } from './offices/offices.component';
//import { LeafletMakersComponent } from './shared/leaflet-makers/leaflet-makers.component';
//import { ActivateaccountComponent } from './activateaccount/activateaccount.component';
//import { IgxTimePickerModule, IgxInputGroupModule, IgxIconModule } from "igniteui-angular";

 
// Import your AvatarModule
//import { AvatarModule } from "ngx-avatar";
//
/**
 * Custom angular notifier options
 */
const avatarColors = ["#95a5a6", "#2c3e50", "#FFB6C1", "#f39c12", "#1abc9c"];
//const avatarConfig = new AvatarConfig(['red','blue','pink']);
 
const customNotifierOptions: NotifierOptions = {
  position: {
		horizontal: {
			position: 'right',
			distance: 12
		},
		vertical: {
			position: 'top',
			distance: 12,
			gap: 10
		}
	},
  theme: 'material',
  behaviour: {
    autoHide: 4000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 3
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};
@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    //LandingComponent,
    NavbarComponent,
    NavbarBackofficeComponent,
    FooterComponent,
    LoginComponent,
    SignupProviderComponent,
    SignupClientComponent,
    ContactComponent,
    ProfileComponent,
    ResetpasswordComponent,
    EdituserinfoComponent,
    EditproviderinfoComponent,
    EditclientinfoComponent,
    ProviderinfotabsComponent,
    ClientinfotabsComponent,
    ProviderbillinginfoComponent,
    LeaftletPathComponent,
    OrdersComponent,
    TestComponent,
    OfficesComponent,
    //LeafletMakersComponent,
    //ActivateaccountComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppRoutingModule,
    HomeModule,
    //NoopAnimationsModule,
    //BrowserAnimationsModule,
    HttpClientModule, NotifierModule.withConfig(customNotifierOptions),
    DataTablesModule,
	  NgSelectModule,
	//RatingModule, 
	//AvatarModule.forRoot({colors: avatarColors}),
	//PipesModule,
	//Ng2SearchPipeModule,
	//IgxTimePickerModule, IgxInputGroupModule, IgxIconModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
