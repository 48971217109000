import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-providerbillinginfo',
  templateUrl: './providerbillinginfo.component.html',
  styleUrls: ['./providerbillinginfo.component.css']
})
export class ProviderbillinginfoComponent implements OnInit {
  updateBillingForm: FormGroup;

  constructor(public fb: FormBuilder) { }

  ngOnInit(): void {
        //Init form
        this.updateBillingForm = this.fb.group({
          taxID: ['',[]],    
          mailingAddress: ['', []],
          paymentMethod: ['', []],
          languageId: ['', []],
          countryId: ['', []]  
        });
  }

}
