import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  private notifier: NotifierService;
  remembered: false;

  constructor(public fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService, 
    notifierSrv: NotifierService)
  { 
    this.notifier = notifierSrv; 
    //Validate if authenticated redirect dashboard
    if (this.authenticationService.currentUserValue) { 
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
      this.submitted = true;
     
      // stop here if form is invalid
      if (this.loginForm.invalid) {
          return;
      }
      this.loading = true;
      this.authenticationService.login(this.f.email.value, this.f.password.value, this.remembered).pipe(first()).subscribe(data => {
        //
		    //console.log(data);
        if (data.success) {
          //Recordar contrase�a
          if (this.remembered) {
            localStorage.setItem("rememberLogin", this.f.email.value);
          } else {
            localStorage.removeItem("rememberLogin");
          }
          //console.log(localStorage.getItem('rememberLogin')); null or email

          this.notifier.notify("success", data.message);
          this.router.navigate([this.returnUrl]);
        } else {
          this.notifier.notify("error", data.message);
        }
        this.loading = false; 
		//window.location.reload();
		//this.router.navigate(['/']);	
      },
      error => {
        this.error = error;
        this.loading = false;
        this.notifier.notify("error", error.message);
      });
    }
	
	onChange(e){
		this.remembered = e.target.checked;	
	}
}
