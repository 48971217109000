import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
//import { EdituserinfoComponent } from './profile/forms/edituserinfo/edituserinfo.component';
import { SignupComponent } from './signup/signup.component';
//import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { ContactComponent } from './contact/contact.component';
import { OrdersComponent } from './orders/orders.component';
import { LeaftletPathComponent } from './shared/leaftlet-path/leaftlet-path.component';
import { AuthGuard } from './helpers/auth.guard';
import { AboutComponent } from './about/about.component';
import { OfficesComponent } from './offices/offices.component';
import { WebappComponent } from './webapp/webapp.component';

const routes: Routes =
	[
		{ path: '', component: HomeComponent},
		{ path: 'home',             component: HomeComponent },
		{ path: 'orders',     component: OrdersComponent, canActivate: [AuthGuard] },
		{ path: 'webapp',     component: WebappComponent, canActivate: [AuthGuard] },
		{ path: 'offices',     component: OfficesComponent, canActivate: [AuthGuard] },
		{ path: 'profile',     component: ProfileComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
		{ path: 'register',           component: SignupComponent },
		{ path: 'about',          component: AboutComponent},
		{ path: 'contact',          component: ContactComponent },
		{ path: 'login',          component: LoginComponent },
		//{ path: 'activateaccount/:verificationToken', component: ActivateaccountComponent },
		{ path: '', redirectTo: 'home', pathMatch: 'full' }
	];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: false,
	  onSameUrlNavigation: 'reload'
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
