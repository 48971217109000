import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  NgbModal,
  NgbAccordion,
  NgbAccordionConfig
} from "@ng-bootstrap/ng-bootstrap";
import { NotifierService } from "angular-notifier";
import { concatAll } from "rxjs/operators";
import { AuthenticationService } from "../services/authentication.service";
import { Userservice } from "../services/user.service";
import { CityMarkers, CountyMarkers } from "../../environments/environment";
import { Generalservice } from "../services/general.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-offices",
  templateUrl: "./offices.component.html",
  styleUrls: ["./offices.component.css"],
  providers: [NgbAccordionConfig]
})
export class OfficesComponent implements OnInit {
  loggedInfo: any;
  Officess: any;
  UserOfficess: any;
  officeForm: FormGroup;
  opened: false;
  Cities: any;
  CitiesTemp: any;
  Counties: any;
  loading: boolean;
  notifier: any;
  error: any;
  mostrar: boolean;
  selected: any;

  constructor(
    config: NgbAccordionConfig,
    public fb: FormBuilder,
    private modalObj: NgbModal,
    private usrSrv: Userservice,
    private authenticationService: AuthenticationService,
    private generalService: Generalservice,
    notifierSrv: NotifierService
  ) {
    config.closeOthers = false;
    //config.type = "info";
    this.notifier = notifierSrv;
  }

  ngOnInit(): void {
    this.loggedInfo = this.authenticationService.currentUserValue["data"];
    this.Cities = this.CitiesTemp = CityMarkers;
    this.Counties = CountyMarkers;


    this.readOffices();
    this.ReadUserOffices(this.loggedInfo.systemaccountId);

    this.officeForm = this.fb.group({
      officeName: ["", [Validators.required]],
      officeAddress: ["", [Validators.required]],
      county: ["", [Validators.required]],
      city: ["", [Validators.required]]
    });
  }

  get f() {
    return this.officeForm.controls;
  }

  public readOffices() {
    this.usrSrv.getOfficesAll().subscribe(response => {
      this.Officess = response.data;
      //  console.log(this.Officess);
    });
    // console.log(this.loggedInfo);
  }

  public ReadUserOffices(uid) {
    let listOffices: any[];
    const offices = this.usrSrv.getClientOfficeByUser(uid);
    offices.subscribe(response => {
      //console.log(response);
      this.UserOfficess = response.data;
    });
  }

  onSubmit() {
    //console.log(this.f);
    this.loading = true;

    // this.generalService
    //   .getCoordinates(this.f.officeAddress.value)
    //   .subscribe(response => {
    //     console.log(response);
    var frmData = {
      clientId: this.loggedInfo.systemaccountId,
      placeName: this.f.officeName.value,
      placeAddress: this.f.officeAddress.value,
      county: this.f.county.value.county,
      city: this.f.city.value.city,
      longitude: 0,
      latitude: 0
    };
    //console.log(frmData);
    //return;
    this.usrSrv.createClientOffice(frmData).subscribe(
      data => {
        if (data.success) {
          this.notifier.notify("success", data.message);
          this.officeForm.reset();
          this.mostrar = true;
        } else {
          this.notifier.notify("error", data.message);
        }
        this.loading = false;
        this.mostrar = false;
        this.readOffices();
        this.ReadUserOffices(this.loggedInfo.systemaccountId);
      },
      error => {
        //alert();
        console.log(error);
        this.error = error;
        this.loading = false;
        this.notifier.notify("error", error.message);
      }
    );
    // });
  }

  public RemoveUserOffice(office) {
    Swal.fire({
      //position: 'top-end',
      icon: "warning",
      title: "Delete " + office.placeName,
      text: "Are you sure you want to delete this item",
      backdrop: false,
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      },
      position: "top",
      background: "#8fd",
      timer: 5000
    }).then(result => {
      if (result.value) {
        this.usrSrv.deleteClientOffice(office.id).subscribe(
          data => {
            if (data.success) {
              this.notifier.notify("success", data.message);
            } else {
              this.notifier.notify("error", data.message);
            }
            this.loading = false;
            this.readOffices();
            this.ReadUserOffices(this.loggedInfo.systemaccountId);
          },
          error => {
            //alert();
            console.log(error);
            this.error = error;
            this.loading = false;
            this.notifier.notify("error", error.message);
          }
        );
      }
    });
  }

  public RemoveOffice(office) {
    Swal.fire({
      //position: 'top-end',
      icon: "warning",
      title: "Delete " + office.placeName,
      text: "Are you sure you want to delete this item",
      backdrop: false,
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      },
      position: "top",
      background: "#8fd",
      timer: 5000
    }).then(result => {
      if (result.value) {
        this.usrSrv
          .getClientOfficeUse(office.clientOfficeId)
          .subscribe(response => {
            console.log(response.data);
            if (response.data.count > 0) {
              this.notifier.notify(
                "info",
                "This offices is in use.It cannot be deleted"
              );
              return;
            }
            this.usrSrv.deleteOffice(office.clientOfficeId).subscribe(
              data => {
                if (data.success) {
                  this.notifier.notify("success", data.message);
                } else {
                  this.notifier.notify("error", data.message);
                }
                this.loading = false;
                this.readOffices();
                this.ReadUserOffices(this.loggedInfo.systemaccountId);
              },
              error => {
                //alert();
                console.log(error);
                this.error = error;
                this.loading = false;
                this.notifier.notify("error", error.message);
              }
            );
          });
      }
    });
  }

  public Use(office) {
    //console.log(office)
    Swal.fire({
      //position: 'top-end',
      icon: "question",
      title: "Use Office " + office.placeName,
      text: "Are you sure you want to use this Office",
      backdrop: false,
      showCancelButton: true,
      confirmButtonText: "Yes, Use it",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      },
      position: "top",
      background: "#8fd",
      timer: 5000
    }).then(result => {
      if (result.value) {
        var data = {
          data: {
            listSelected: new Array(),
            uid: this.loggedInfo.systemaccountId
          }
        };
        office.id = office.clientOfficeId;
        data.data.listSelected.push(office);
        //console.log(data);
        //return;
        this.usrSrv.useOffices(data).subscribe(
          data => {
            if (data.success) {
              this.notifier.notify("success", data.message);
            } else {
              this.notifier.notify("error", data.message);
            }
            this.loading = false;
            this.readOffices();
            this.ReadUserOffices(this.loggedInfo.systemaccountId);
          },
          error => {
            //alert();
            console.log(error);
            this.error = error;
            this.loading = false;
            this.notifier.notify("error", error.message);
          }
        );
      }
    });
  }

  onChange() {
    var data = this.CitiesTemp.filter(it => {
      return it.county.includes(this.selected.county);
    });
    this.Cities = data;
   // console.log(this.Cities);
  }
}
