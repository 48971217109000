import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Generalservice } from 'src/app/services/general.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  isLoading:boolean;
  private notifier: NotifierService;
  
  constructor( notifierSrv: NotifierService, public fb: FormBuilder, private grlService: Generalservice) { 
	this.notifier = notifierSrv
	this.isLoading = false;
  }

  ngOnInit(): void {
	this.contactForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required]],
      subject: ['', [Validators.required]],
    });
  }
  
  onSend():void{
	  this.isLoading = true;
	  
	  this.grlService.sendEmailContact(this.contactForm.value).subscribe((response)=>{
		this.notifier.notify("error", response.message);
        this.isLoading = false;
	  });
  }
}
