import { Component, OnInit, Input } from '@angular/core';
import { SystemAccount } from '../models/user.model';
import { Userservice } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { Languages } from '../../environments/environment';

export interface IAlert {
    id: number;
    type: string;
    strong?: string;
    message: string;
    icon?: string;
}

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	@Input() public alerts: Array<IAlert> = [];
	private backup: Array<IAlert>;
	userProfile: any;
    loggedInfo: any;
	languages: any;
    
	constructor(private usrSrv: Userservice, private authenticationService: AuthenticationService,) {
		 this.languages = Languages;
	}	
	
	close(alert: IAlert) {
			this.alerts.splice(this.alerts.indexOf(alert), 1);
	}

    ngOnInit() {
		if(this.authenticationService.currentUserValue){
			this.loggedInfo = this.authenticationService.currentUserValue['data'];
			//console.log(this.loggedInfo);
			this.usrSrv.getSystemaccountByEmail(this.loggedInfo).subscribe((response)=>{
				//console.log(response.data);
				this.userProfile = response.data;
				if(!this.userProfile.name || !this.userProfile.lastname || !this.userProfile.mobile || !this.userProfile.address){
					this.alerts.push({
					  id: 1,
					  type: 'warning',
					  strong: 'Important!  ',
					  message: 'Dear ' + this.userProfile.name + ', it is very important, you complete the required profile information',
					  icon: 'ni ni-bell-55'
					});
					this.backup = this.alerts.map((alert: IAlert) => Object.assign({}, alert));
				}
			});  
		}	
	}
}
