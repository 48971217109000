
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Userservice } from 'src/app/services/user.service';
import { Generalservice } from 'src/app/services/general.service';
//import { Swallservice } from 'src/app/services/swall.service';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { PATTERN } from '../../../../environments/environment';
import { ConfirmedValidator } from '../../../directives/passwordConfirmValidator';
import {SignupComponent} from '../../signup.component';
import  Swal  from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-signup-provider',
  templateUrl: './signup-provider.component.html',
  styleUrls: ['./signup-provider.component.css']
})
export class SignupProviderComponent implements OnInit {
  
  @Input() providerOpt: any; 
  providerForm: FormGroup;
  private notifier: NotifierService;
  isLoading: boolean;
  userExist: Boolean = false;
  emailExist:Boolean = false;
  theModal:any;

  constructor(private router: Router, private userSrv: Userservice, private grlService: Generalservice,  notifierSrv: NotifierService, public fb: FormBuilder, public signupParent: SignupComponent) {
    this.isLoading = false;
    this.notifier = notifierSrv; 
  }

  ngOnInit(): void {
    this.providerForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      lastname: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(PATTERN.password)]],
      retypePassword: ['',[Validators.required]],
      checkPrivacy: [false, [Validators.requiredTrue]],
      userGroup:['PRO', []],
      group:[this.providerOpt.providers.name, []]
    }, 
    { 
      validator: ConfirmedValidator('password', 'retypePassword')
    });
  }

  onSubmit(): void{
    this.isLoading = true;
    this.userSrv.register(this.providerForm.value).subscribe((response)=>{
      if(response.success){
       // console.log(response);
       // console.log(this.providerForm.value);
        /*this.grlService.sendEmail(this.providerForm.value).subscribe((resp)=>{
          this.notifier.notify("success", response.message);
          this.providerForm.reset();
          this.isLoading = false;
        });*/
		//Swal.fire('Thank you...', 'You registered succesfully!', 'success');
		
		Swal.fire({
		  //position: 'top-end',
		  icon: 'success',
		  title: 'You registered succesfully',
		  text:'redirecting to login page',
		  backdrop:false,
		  showConfirmButton: true,
		  showClass: {
			popup: 'animate__animated animate__fadeInDown'
		  },
			hideClass: {
				popup: 'animate__animated animate__fadeOutUp'
			  },
			position: 'top-end',
			background: '#8fd',	
		  timer: 4000
		}).then((result)=>{
			this.closeThis();
			this.router.navigate(['/login']);
		})
      }
      else{
		  
        this.notifier.notify("error", response.message);
        this.isLoading = false;
      }
    }, (error)=>{
      this.isLoading = false;
      this.notifier.notify("error", error.message);
    });
  }

  //Evento cerrar Dialogo
  closeThis(): void {
    this.providerForm.reset();
    this.signupParent.closeModal();
  }

   async validateUser(field: string){
     if(field === 'username'){
      this.userSrv.getSystemaccountByUsername(this.providerForm.value).subscribe((response)=>{
        if(response.success)
        {
          this.notifier.notify("info", "This username has been already taken");
          this.userExist = true;
          return;
        }
        this.userExist = false;
      });
     }
     else if(field === 'email'){
      this.userSrv.getSystemaccountByEmail(this.providerForm.value).subscribe((response)=>{
        if(response.success)
        {
          this.notifier.notify("info", "This email address is in use");
          this.emailExist = true;
          return;
        }
        this.emailExist = false;
      });
     }
  }
}
