import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { AuthenticationService } from '../../services/authentication.service';
import { Userservice } from '../../services/user.service';
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { finalize, map, catchError } from 'rxjs/operators';
import { Exchangeservice } from '../../services/exchange.service';
import { SystemAccount } from '../../models/user.model';

@Component({
    selector: 'app-navbar-backoffice',
    templateUrl: './navbar-backoffice.component.html',
    styleUrls: ['./navbar-backoffice.component.scss']
})
export class NavbarBackofficeComponent implements OnInit {
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
	image: string | SafeUrl = "";
	loggedInfo: any;
    userProfile: SystemAccount;

    constructor(public location: Location, private router: Router, private authenticationService: AuthenticationService,  private exchangeSrv: Exchangeservice, private sanitizer: DomSanitizer, private usrSrv: Userservice) {
    }

    ngOnInit() {
		this.loggedInfo = this.authenticationService.currentUserValue['data'];
		//console.log(this.loggedInfo)
		this.reloadUserInfo();	
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
        if (event instanceof NavigationStart) {
           if (event.url != this.lastPoppedUrl)
               this.yScrollStack.push(window.scrollY);
       } else if (event instanceof NavigationEnd) {
           if (event.url == this.lastPoppedUrl) {
               this.lastPoppedUrl = undefined;
               window.scrollTo(0, this.yScrollStack.pop());
           } else
               window.scrollTo(0, 0);
       }
     });
     this.location.subscribe((ev:PopStateEvent) => {
         this.lastPoppedUrl = ev.url;
     });
	 
	 //Event for changing avatar
		this.exchangeSrv.change.subscribe(avatar => {
			  this.image = avatar;
		});
    }

	public reloadUserInfo(){
      this.usrSrv.getSystemaccountByEmail(this.loggedInfo).subscribe((response)=>{
        this.userProfile = response.data;
		this.usrSrv.getUserPhoto(response.data).subscribe((res)=>{
			this.image = res;
		});
      });   
    }
	
    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if( titlee === '#/home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '#/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }

    logout(){
        //this.app.logout();
        this.authenticationService.logout();
        this.router.navigate(['/']);
    }
	
}
