import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpEvent, HttpErrorResponse, HttpEventType  } from "@angular/common/http";
import { API_BASE_URL } from "../../environments/environment";
import { Observable } from 'rxjs';
import { map } from  'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Generalservice {
  constructor(private http: HttpClient) { }

  getPaises(): Observable<any>{
    return this.http.get(API_BASE_URL + '/general/countries');
  }

  sendEmail(data: any): Observable<any> {
    return this.http.post(API_BASE_URL + '/email/send', data);
  }
  
  sendEmailContact(data: any): Observable<any> {
    return this.http.post(API_BASE_URL + '/email/contact', data);
  }

  public uploadFileProgress(url: string, file): Observable<any> {
    const fileData = new FormData();
    fileData.append('file', file);
    fileData.append('url', url);
    return this.http.post<any>(API_BASE_URL + "/uploadfile", fileData, 
      { 
        reportProgress: true, 
        observe: 'events'
    });
  }

  public downloadFileProgress(filename: string): Observable<any> {
    var file = this.http.get(API_BASE_URL + '/download/' + filename);
    return file;
  }

  public getCoordinates(address:string): Observable<any> {
    return this.http.get<any>("https://positionstack.com/geo_api.php?query=" + address);
  }
}
