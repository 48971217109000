import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Languages, PaymentMethods } from '../../../../environments/environment';
import {NgbNavConfig} from '@ng-bootstrap/ng-bootstrap';
import { ProfileComponent } from '../../profile.component';


@Component({
  selector: 'app-editproviderinfo',
  templateUrl: './editproviderinfo.component.html',
  styleUrls: ['./editproviderinfo.component.css'],
  providers: [NgbNavConfig] // add NgbNavConfig to the component providers
})

export class EditproviderinfoComponent implements OnInit {
  updateProfessionalForm: FormGroup;
  currentJustify = 'fill'; // justified, start, center, end, fill
  active: any;
  languages: {};
  paymentMethods: {};

  constructor( public fb: FormBuilder, config: NgbNavConfig, public profileParent: ProfileComponent) {
    this.languages = Languages;
    this.paymentMethods = PaymentMethods;
	
    this.active = 'top';
    // customize default values 
    config.destroyOnHide = false;
    config.roles = false;
   }

  ngOnInit(): void {
    //load langs
   

  }
  
    //Evento cerrar Dialogo
	closeThis(): void {
        this.profileParent.closeModal();
	}
}
