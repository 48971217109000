import { Component, OnInit, Input } from '@angular/core';

export interface IAlert {
    id: number;
    type: string;
    strong?: string;
    message: string;
    icon?: string;
}

@Component({
  selector: 'app-alerts-section',
  templateUrl: './alerts-section.component.html',
  styleUrls: ['./alerts-section.component.css']
})
export class AlertsSectionComponent {
  @Input()
  public alerts: Array<IAlert> = [];
  private backup: Array<IAlert>;
  constructor() {
      this.alerts.push({
          id: 3,
          type: 'warning',
          strong: 'Warning!',
          message: 'This is a warning alert—check it out!',
          icon: 'ni ni-bell-55'
      });
      this.backup = this.alerts.map((alert: IAlert) => Object.assign({}, alert));
  }

  close(alert: IAlert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

}
