import { Component, OnDestroy, OnInit, ElementRef  } from '@angular/core';
import * as Leaflet from 'leaflet';
import { antPath } from 'leaflet-ant-path';
import { Userservice } from '../../services/user.service';
import { NotifierService } from 'angular-notifier';
import { AuthenticationService } from '../../services/authentication.service';
import { Generalservice } from '../../services/general.service';
import { MarkerExt } from '../../helpers/marker-ext';

	
@Component({
  selector: 'app-leaftlet-path',
  templateUrl: './leaftlet-path.component.html',
  styleUrls: ['./leaftlet-path.component.css']
})


export class LeaftletPathComponent implements OnInit {
	title = 'leafletApps';
	map: Leaflet.Map;
	cityList: any;
	countyList: any;
	colored: boolean = false;
	markerSelected: any;
	currentZoom: number = 10;
	Markers: any;
	private notifier: NotifierService;
	isLoading: boolean;
	currentUser: any;
  
	constructor(notifierSrv: NotifierService, private gralSrv: Generalservice, private usrSrv: Userservice, private authSrv: AuthenticationService) 
	{
		this.isLoading = false;
		this.notifier = notifierSrv; 
		//this.currentUser = this.authSrv.currentUserValue['data'];
		
		this.Markers = [];	
		this.countyList = [
			{
				"county": "Miami",
				"state": "Florida",
				"longitude": -80.315619303387,
				"latitude": 25.871717249403,
				"selected": false				
			 },
			{
				"county": "Palm Beach County",
				"state": "Florida",
				"longitude": -80.03661,
				"latitude": 26.70288,
				"selected": false
			},
			{
				"county": "Broward",
				"state": "Florida",
				"longitude": -80.143585,
				"latitude": 26.124163,
				"selected": false
			 },	
							 
		];
		this.cityList = 
			[
				 {
					"city": "Hialeah",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.315619303387,
					"latitude": 25.871717249403 ,
					"selected": false
				 },
				 {
					"city": "Miami Beach",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.13092,
					"latitude": 25.786587,
					"selected": false
				 },
				 {
					"city": "Aventura",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.15661,
					"latitude": 25.91715 ,
					"selected": false
				 },
				{
					"city": "Florida City",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.484383,
					"latitude": 25.451331,
					"selected": false
				},
				 {
					"city": "Homestead",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.4650552,
					"latitude": 25.4941845,
					"selected": false
				 },
				{
					"city": "Doral",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.3339605088,
					"latitude": 25.8216306564,
					"selected": false
				},
				{
					"city": "Hialeah Garden",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude":	-80.342165298,
					"latitude":	25.873663172,
					"selected": false
				},
				{
					"city": "Islandia",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude":  -80.207389,
					"latitude": 25.414727 ,
					"selected": false 
				},
				{
					"city": "Miami Gardens",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.269920,
					"latitude": 25.942122,
					"selected": false
				},
				{
					"city": "Miami Springs",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.2895,
					"latitude": 25.82232 ,
					"selected": false
				},
				{
					"city": "North Bay Village",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.15394,
					"latitude": 25.84621 ,
					"selected": false
				},
				{
					"city": "North Miami",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.18671,
					"latitude": 25.89009 ,
					"selected": false
				},
				{
					"city": "North Miami Beach",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.166832666,
					"latitude":  25.924829634 ,
					"selected": false
				},
				{
					"city": "Opa-Locka",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.25033,
					"latitude": 25.90232 ,
					"selected": false
				},
				{
					"city": "South Miami",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.290498838,
					"latitude": 25.706163842 ,
					"selected": false
				},
				{
					"city": "Sunny Isles Beach",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.12282,
					"latitude": 25.95065 ,
					"selected": false
				},
				{
					"city": "Sweetwater",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.37311,
					"latitude": 25.76343 ,
					"selected": false
				},
				{
					"city": "West Miami",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.291332168,
					"latitude": 25.754330316 ,
					"selected": false
				},
				{
					"city": "Miami-Dade",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.21666667,
					"latitude": 25.783333,
					"selected": false
				},
				{
					"city": "Centro",
					"county": "",
					"state": "",
					"longitude": -80.5,
					"latitude": 25.783333,
					"selected": false
				},
				
			];
	}
	selected:any = "";
	selectedCity:any = [];
	selectedCitySave:any = [];
	legend: any;
	
	cityData = {
		"city": "",
		"county": "",
		"state": "",
		"longitude": 0,
		"latitude": 0,
		"selected": false
	};
	
	ngOnInit(): void {
		this.currentUser = this.authSrv.currentUserValue['data'];
		this.leafletMap();
		antPath([[28.644800, 77.216721], [34.1526, 77.5771]],
		  { color: '#FF0000', weight: 5, opacity: 0.6 })
		  .addTo(this.map);	
	};
	
	leafletMap(): void {
		var markers = [];
		var cities = [];
		var markersCounty = [];
		
		this.map = Leaflet.map('map').setView([-80.8,25.783333], this.currentZoom);
		this.map.doubleClickZoom.disable();
		//this.map.options.minZoom = 9;
		this.map.options.maxZoom = 10;
		
		Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
		  attribution: 'adlitem.k-nos.com',
		  //doubleClickZoom = FALSE,
		}).addTo(this.map);
		
		var greenIcon = new Leaflet.Icon({
				  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
				  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
				  iconSize: [25, 41],
				  iconAnchor: [12, 41],
				  popupAnchor: [1, -34],
				  shadowSize: [41, 41]
			});
		var blueIcon = new Leaflet.Icon({
				  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
				  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
				  iconSize: [25, 41],
				  iconAnchor: [12, 41],
				  popupAnchor: [1, -34],
				  shadowSize: [41, 41]
			});
		//Prepare markers for County
		for(const county of this.countyList) {
			var marker = new MarkerExt(county.latitude, county.longitude);
			markersCounty.push(marker);
			marker.bindPopup(county.county);
			marker.bindTooltip(county.county);
		}	
		
		this.usrSrv.getAreasByUser(this.currentUser).subscribe((response)=>{
			//console.log(response);
			//alert(response.success);
			if(response.success){
				this.selectedCitySave = response.data;
			}	
				//Markers for cities
				for (const city of this.cityList) {
					var marker = new MarkerExt(city.latitude, city.longitude); 
					markers.push(marker);
					
					marker.county = city.county;
					marker.city = city.city;
					marker.state = city.state;
					marker.selected = city.selected;
					
					marker.addTo(this.map);
					marker.bindPopup(city.city);
					marker.openPopup();
					
					if(city.city == 'Centro')
						marker.remove();
					
					//Set default icon color
					marker.setIcon(blueIcon);
					//alert(this.selectedCitySave.length);
					if(this.selectedCitySave.length > 0){
						for(let m of this.selectedCitySave){
							//console.log(m);
							if(m.city == marker.city)
							{
								marker.setIcon(greenIcon);
							}
						}
					}
					
					marker.bindTooltip(city.city);
					
					marker.on('click', function(e) {
						e.target.selected = !e.target.selected;

						this.cityData = {
							"city": e.target.city,
							"county": e.target.county,
							"state": e.target.state,
							"longitude": city.longitude,
							"latitude": city.latitude,
							"selected": e.target.selected
						};
						
						if(e.target.selected){
							e.target.setIcon(greenIcon); 
							this.selectedCity.push(e.target);
							this.selectedCitySave.push(this.cityData);
						}
						else {
							e.target.setIcon(blueIcon);
							this.RemoveElementFromArray(this.selectedCity, e.target);
							this.RemoveElementFromArraySave(this.selectedCitySave, this.cityData);
						}
						//console.log(this.selectedCity);
					},this);
				}
			//}
		});	
		//Zooming
		this.map.on('zoomend', function() {
			this.usrSrv.getAreasByUser(this.currentUser).subscribe((response)=>{
			//console.log(response);
			if(response.success){
				this.selectedCitySave = response.data;
			}	
				this.currentZoom = this.map.getZoom();
				if(this.currentZoom <= 9 && markers.length > 0){
					//Add Cities layer
					for(const m of markers)
					{
						m.remove();
					}
					//Add county Layer
					for(const city of this.countyList)
					{
						var marker = new MarkerExt(city.latitude, city.longitude);
						markersCounty.push(marker);
						//Adding other attrib
						marker.county = city.county;
						marker.state = city.state;
						
						marker.addTo(this.map);
						marker.bindPopup(city.county);
						marker.openPopup();
						if(city.county == 'Centro')
							marker.remove();
						marker.setIcon(blueIcon);
						marker.bindTooltip(city.county);
						
						marker.on('click', function(e) {
							//console.log(e.target);
							e.target.selected = !e.target.selected;		
							if(e.target.selected){
								e.target.setIcon(greenIcon); }
							else {
								e.target.setIcon(blueIcon);
							}
						},this);
					}
				}
				else if(this.currentZoom >= 10){
					//Remove county layers
					markers = [];
					for(const mc of markersCounty)
					{
						mc.remove();
					}
					//this.selectedCity = [];
					//this.selectedCitySave = [];
					//console.log(this.cityList);
					for (const city of this.cityList) {
						var marker = new MarkerExt(city.latitude, city.longitude);
						markers.push(marker); 
						marker.openPopup();
						//Adding other attrib
						marker.county = city.county;
						marker.city = city.city;
						marker.state = city.state;
						marker.selected = city.selected;
						marker.addTo(this.map);
						if(city.city == 'Centro')
							marker.remove();
						
						//Set default icon color
						marker.setIcon(blueIcon);
						
						for(let m of this.selectedCitySave){
							//console.log(m);
							if(m.city == marker.city)
							{
								marker.setIcon(greenIcon);
							}
						}
						
						marker.bindTooltip(city.city);
					
						marker.on('click', function(e) {
							e.target.selected = !e.target.selected;

							this.cityData = {
								"city": e.target.city,
								"county": e.target.county,
								"state": e.target.state,
								"longitude": city.longitude,
								"latitude": city.latitude,
								"selected": e.target.selected
							};
							//console.log(this.cityData);
							if(e.target.selected){
								e.target.setIcon(greenIcon); 
								this.selectedCity.push(e.target);
								this.selectedCitySave.push(this.cityData);
							}
							else {
								e.target.setIcon(blueIcon);
								this.RemoveElementFromArray(this.selectedCity, e.target);
								this.RemoveElementFromArraySave(this.selectedCitySave, this.cityData);
							}
							//console.log(this.selectedCity);
						},this);
					}	
					//console.log(markers);
				}	
			});
		}, this);
	}
	
	RemoveElementFromArray(arrayData, element) {
		arrayData.forEach((value,index)=>{
			if(value==element) arrayData.splice(index,1);
		});
		
	}
	RemoveElementFromArraySave(arrayData, element) {
		arrayData.forEach((value,index)=>{
			if(value.city==element.city) {
				arrayData.splice(index, 1);
			}
		});
		
	}
	SaveAreas(){
		//console.log(this.selectedCitySave);
		var data = [];
		data[0] = this.selectedCitySave;
		data[1] = this.currentUser;
		
		this.usrSrv.createArea(data).subscribe((response)=>{
			//console.log(response);
			if(response.success){
			  this.notifier.notify('success', response.message);
			}else{
			  this.notifier.notify('warning',  response.message);
			}
			this.isLoading = false;
		}, 
		(error)=>{
			this.notifier.notify('error',  error);
			console.log(error);
			this.isLoading = false;
		});	
	}
	
	ngOnDestroy(): void {
		//this.map.remove();
	}
}
