// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};
  
    //export const API_BASE_URL = 'http://localhost:3002/api';
  
    export const API_URL = 'https://api.k-nos.com';
    export const API_BASE_URL = 'https://api.k-nos.com/api';
  	export const  BASE_URL = 'https://adlitem.k-nos.com';  
 
  export const PROFILE_PATH = 'upload/profile/';
  export const PROFILE_DOC = 'upload/docs/';
  export const PROFILE_DEFAULT = '../../assets/img/profile/';
  export const DOC_PATH = 'upload/w-9/';


export const PATTERN = {
    /* At least 6 characters in length, lowercase and numbers */
	
    username : "^[a-z0-9_-]{6,20}$",
    /*
      At least 8 characters in length
      Lowercase letters
      Uppercase letters
      Numbers
      Special characters 
    */
    password: "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}",
    phone: "^((\\+91-?)|0)?[0-9]{10}$", 
    email: "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
  };


  export const Languages = [
    // {languageId: 1, code:"EN", name: "English"},
    {languageId: 2, code:"ES", name: "Spanish"},
    {languageId: 3, code:"FR", name: "French"},
    
    {languageId: 4, code:"RU", name: "Russian"},
    {languageId: 5, code:"CN", name: "Mandarin"},
    {languageId: 6, code:"HT", name: "Haitian"},
    {languageId: 7, code:"CR", name: "Croatian"},
    {languageId: 8, code:"RO", name: "Romanian"},
    {languageId: 9, code:"DE", name: "German"},
    {languageId: 10, code:"TR", name: "Turkish"},
    {languageId: 11, code:"PT", name: "Portuguesse"}
  ];
  
  export const CertificationType =[
      {id: 1, type: "Federal"},
      {id: 2, type: "State"}
  ];

  export const AtivityType =[
	{id: 1, type: "REMOTE"},
	{id: 2, type: "FACE-TO-FACE"}
];

export const OrderTarget =[
	{id: 1, name: "Court Reporter Only"},
	{id: 2, name: "Other Providers"},
	{id: 3, name: "Both Other Providers and Court Reporter"}
];

  export const StarRate =[
	{id: 5, value: "Rate: " + 5 + '\u2605', text: ' \u2605 \u2605 \u2605 \u2605 \u2605'},
	{id: 4, value: "Rate: " + 4 + '\u2605', text: ' \u2605 \u2605 \u2605 \u2605'},
	{id: 3, value: "Rate: " + 3 + '\u2605', text: ' \u2605 \u2605 \u2605'},
	{id: 2, value: "Rate: " + 2 + '\u2605', text: ' \u2605 \u2605'},
	{id: 1, value: "Rate: " + 1 + '\u2605', text: ' \u2605'},
];
  
  export const ClientActivities = [
      {areaId: 1, name: "Inmigration"},
      {areaId: 2, name: "Civil"},
      {areaId: 3, name: "Family"},
      {areaId: 4, name: "Criminal"},
      {areaId: 5, name: "Labor"},
      {areaId: 6, name: "Comercial"},
      {areaId: 9, name: "Other"}
  ];
  
  export const ProviderActivities =[
      {id: 1, type: "Trial"},
      {id: 2, type: "Deposition"},
      {id: 3, type: "Mediation"},
      {id: 4, type: "Arbitration"},
      {id: 5, type: "EUOs (Examination Under Oath)"},
      {id: 6, type: "IME (Independent Medical Examination)"},
      {id: 7, type: "Immigration Interview"},
      {id: 8, type: "Remote Interpretation (Phone Call, ViedeoConference)"},
      {id: 9, type: "Other"}
  ];
  
  export const PaymentMethods = [
    {id: 1, method: "Credit Card"},
    {id: 2, method: "Bank Account"},
    {id: 3, method: "PayPal"}
  ];
  
  export const Days = [
    {id: 1, day: "Monday"},
    {id: 2, day: "Tuesday"},
    {id: 3, day: "Wednesday"},
    {id: 4, day: "Thursday"},
    {id: 5, day: "Friday"},
    {id: 6, day: "Saturday"},
    {id: 7, day: "Sunday"},
  ];
  
  export const Rates = [
    {range: "60-80",  text: "$60 -  80 /H"},
    {range: "70-90",  text: "$70 -  90 /H"},
    {range: "80-100", text: "$80 - 100 /H"},
    {range: "90-110", text: "$90 - 110 /H"},
  ];
  
  export const Providers = [
    {name: 'Translator', val: 'translator'},
    {name: 'Interpreter', val: 'interpreter'},
    {name: 'Court Report', val: 'courtreport'},
    {name: 'Substitute Attorney', val: 'substitutelawyer'},
  ];
  
  export const Clients = [
      {name: 'Corporate Support', val: 'lawfirm'},
      {name: 'Attorney', val: 'attorney'},
  ]; 

  export const UserType = [
    { name: "Client", val: "CLI" },
    { name: "Provider", val: "PRO" },
  ]; 

   export const States = [
     {
       Id: 1,
       state: "Florida",
     },
   ]; 

  export const CountyMarkers = [
    {
      Id: 1,
      county: "Miami Dade County",
      state: "Florida",
      longitude: -80.315619303387,
      latitude: 25.871717249403,
      selected: false,
    },
    // {
      // Id: 2,
      // county: "Palm Beach",
      // state: "Florida",
      // longitude: -80.03661,
      // latitude: 26.70288,
      // selected: false,
    // },
    {
      Id: 3,
      county: "Broward",
      state: "Florida",
      longitude: -80.143585,
      latitude: 26.124163,
      selected: false,
    },
  ];

  export const CityMarkers = 
			[   
				 {
					"Id": 1, 
					"city": "Hialeah",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.315619303387,
					"latitude": 25.871717249403 ,
					"selected": false,
					"color": 'green'
				 },
				 {
					"Id": 2,
					"city": "Miami Beach",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.13092,
					"latitude": 25.786587,
					"selected": false,
					"color": 'green'
				 },
				{
					"Id": 3,
					"city": "Aventura",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.15661,
					"latitude": 25.91715 ,
					"selected": false,
					"color": 'green'
				 },
				{
					"Id": 4,
					"city": "Florida City",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.484383,
					"latitude": 25.451331,
					"selected": false,
					"color": 'green'
				},
				 {
					"Id": 5,
					"city": "Homestead",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.4650552,
					"latitude": 25.4941845,
					"selected": false,
					"color": 'green'
				 },
				{
					"Id": 6,
					"city": "Doral",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.3339605088,
					"latitude": 25.8216306564,
					"selected": false,
					"color": 'green'
				},
				{
					"Id": 7,
					"city": "Hialeah Garden",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude":	-80.342165298,
					"latitude":	25.873663172,
					"selected": false,
					"color": 'green'
				},
				{
					"Id": 8,
					"city": "Islandia",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude":  -80.207389,
					"latitude": 25.414727 ,
					"selected": false,
					"color": 'green' 
				},
				{
					"Id": 9,
					"city": "Miami Gardens",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.269920,
					"latitude": 25.942122,
					"selected": false,
					"color": 'green'
				},
				{
					"Id": 10,
					"city": "Miami Springs",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.2895,
					"latitude": 25.82232 ,
					"selected": false,
					"color": 'green'
				},
				{
					"Id": 11,
					"city": "North Bay Village",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.15394,
					"latitude": 25.84621 ,
					"selected": false,
					"color": 'green'
				},
				{
					"Id": 12,
					"city": "North Miami",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.18671,
					"latitude": 25.89009 ,
					"selected": false,
					"color": 'green'
				},
				{
					"Id": 13,
					"city": "North Miami Beach",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.166832666,
					"latitude":  25.924829634 ,
					"selected": false,
					"color": 'green'
				},
				{
					"Id": 14,
					"city": "Opa-Locka",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.25033,
					"latitude": 25.90232 ,
					"selected": false,
					"color": 'green'
				},
				{
					"Id": 15,
					"city": "South Miami",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.290498838,
					"latitude": 25.706163842 ,
					"selected": false,
					"color": 'green'
				},
				{
					"Id": 16,
					"city": "Sunny Isles Beach",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.12282,
					"latitude": 25.95065 ,
					"selected": false,
					"color": 'green'
				},
				{
					"Id": 17,
					"city": "Sweetwater",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.37311,
					"latitude": 25.76343 ,
					"selected": false,
					"color": 'green'
				},
				{
					"Id": 18,
					"city": "West Miami",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.291332168,
					"latitude": 25.754330316 ,
					"selected": false,
					"color": 'green'
				},
				{ 
					"Id": 19,
					"city": "Miami-Dade",
					"county": "Miami Dade County",
					"state": "Florida",
					"longitude": -80.21666667,
					"latitude": 25.783333,
					"selected": false,
					"color": 'green'
				},
				{ 
					"Id": 20,
					"city": "Fort Lauderdale",
					"county": "Broward",
					"state": "Florida",
					"longitude": -80.137317,
					"latitude": 26.122439,
					"selected": false,
					"color": 'green'
				},
				{ 
					"Id": 21,
					"city": "Coral Springs",
					"county": "Broward",
					"state": "Florida",
					"longitude": -80.270604,
					"latitude": 26.271192,
					"selected": false,
					"color": 'green'
				},
				{ 
					"Id": 22,
					"city": "Pompano Beach",
					"county": "Broward",
					"state": "Florida",
					"longitude": -80.124767,
					"latitude": 26.237860,
					"selected": false,
					"color": 'green'
				},
				{ 
					"Id": 23,
					"city": "Weston",
					"county": "Broward",
					"state": "Florida",
					"longitude": -80.399775,
					"latitude": 26.100365,
					"selected": false,
					"color": 'green'
				},
				{ 
					"Id": 24,
					"city": "Cooper City",
					"county": "Broward",
					"state": "Florida",
					"longitude": -80.271716,
					"latitude": 26.057311,
					"selected": false,
					"color": 'green'
				},
				{ 
					"Id": 25,
					"city": "Oakland Park",
					"county": "Broward",
					"state": "Florida",
					"longitude": -80.131989,
					"latitude": 26.172307,
					"selected": false,
					"color": 'green'
				},
				{ 
					"Id": 26,
					"city": "West Park",
					"county": "Broward",
					"state": "Florida",
					"longitude": -80.202558,
					"latitude": 25.984607,
					"selected": false,
					"color": 'green'
				},
				{ 
					"Id": 27,
					"city": "Kendall Green",
					"county": "Broward",
					"state": "Florida",
					"longitude": -80.122724,
					"latitude": 26.266850,
					"selected": false,
					"color": 'green'
				},
				{ 
					"Id": 28,
					"city": "Deerfield Beach",
					"county": "Broward",
					"state": "Florida",
					"longitude": -80.099766,
					"latitude": 26.318412,
					"selected": false,
					"color": 'green'
				}
					
			];

	export const JudgeMarkers = [
        {
          Id: 1,
		  name:"Miami-Dade Federal Judge",
          city: "Miami-Dade",
		  county: "Miami",
          state: "Florida",
          longitude: -80.315619303387,
          latitude: 25.871717249403,
          selected: false,
        },
        {
          Id: 2,
          name:"Doral Judge",
          city: "Doral",
		  county: "Miami",
          state: "Florida",
		  longitude: -80.03661,
          latitude: 26.70288,
          selected: false,
        }
      ];

    export const LATITUDE = 25.670493353936145;
    export const LONGITUDE = -80.24045694619417;
	export const LATITUDE_DELTA = 0.51191050381801197;
    export const LONGITUDE_DELTA = 0.5550787180662013;
    
	export const Header_Height = 60;

	export const COLORS_APP = {
		Buttons:"#160650",
		Bar:"#023002",
		HeaderText:"#ffffff",
		HeaderBackground: "#160650",
		Text:"#000000",
		Icons:"#160650",
		Danger: "#860404",
		Warning: "#B6E23E",
		Success:"#087A08",
		Morado1:"#572364",
		Morado2:"#8a36d2",
		Morado3:"#4b0081",
		Morado4:"#551a8b",
		Morado5:"#321350",
		Star5:"#B6E23E",
		Star4:"#087A08",
		Star3:"#860404",
		Star2:"#860404",
		Star1:"#860404",
	} 	

	/*
	
ALTER TABLE `clientorders`
  ADD CONSTRAINT `acceptorders_ibfk_1` FOREIGN KEY (`providerId`) REFERENCES `provider` (`systemaccountId`),
  ADD CONSTRAINT `acceptorders_ibfk_2` FOREIGN KEY (`orderId`) REFERENCES `clientorders` (`orderId`)
 
	*/