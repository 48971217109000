import { Component, OnInit, Input} from "@angular/core";
import { Userservice } from "../services/user.service";
import { AuthenticationService } from "../services/authentication.service";
import { NotifierService } from "angular-notifier";
import { NgbModal, ModalDismissReasons, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { timeDiff } from "../helpers/date";
import { Generalservice } from '../services/general.service';
import { Subscription, Observable, of } from 'rxjs';
import { API_URL, API_BASE_URL, BASE_URL, PROFILE_DOC, PROFILE_DEFAULT, Rates } from "../../environments/environment";
import { finalize, map, catchError } from 'rxjs/operators';
import { HttpClientModule, HttpClient, HttpEventType, HttpErrorResponse } from "@angular/common/http";
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { Exchangeservice } from '../services/exchange.service';


import Swal from "sweetalert2";
import {
  ProviderActivities,
  Languages,
  CityMarkers,
  CountyMarkers,
  AtivityType,
  OrderTarget
} from "../../environments/environment";
import { StarRatingComponent } from "ng-starrating";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.css"]
})
export class OrdersComponent implements OnInit {
  loggedInfo: any;
  public theModal: NgbModal;
  closeable: Boolean = false;
  mostrar: Boolean = true;
  providersCoverage: any;
  Languages: any;
  orderForm: FormGroup;
  customize: Boolean = false;
  providers: any;
  maxRate = 5;
  currentRate = 2.2;
  orders: any;
  loading: Boolean = true;
  notifier: any;
  error: any;
  providerOrders: any;
  Cities: any;
  CitiesTemp: any;
  Counties: any;
  selected: any;
  Offices: any;
  OfficeSelected: any;
  Activities: any;
  ActivitySelected: any;
  LanguageSelected: any;
  ModeSelected: any;
  ActivitiesType: any;
  OrderTargets: any;
  TargetSelected: any;
  ProviderActivityData: any;
  Penalizacion: any;
  PenalizacionMsg: any;
  PorcientoH: any;
  HContratadas:any;
  timeHbefore:any;
  HorasAntes:any;
  // time4Hbefore:any;
  // time8Hbefore:any;
  // time12Hbefore:any;

  inProgress = false;
  completed = false;
  fileProgress = 0;
  @Input() storeUrl: string;
  fileName: string;
  sub: Subscription;
  filepdf: string = "";
  event: any;
  ApiBaseUrl: string = API_BASE_URL;

  constructor(
    public fb: FormBuilder,
    config: NgbModalConfig,
    private modalObj: NgbModal,
    private usrSrv: Userservice,
    private gralSrv: Generalservice,
    private authenticationService: AuthenticationService,
    notifierSrv: NotifierService,
    private sanitizer: DomSanitizer,
    private exchangeSrv: Exchangeservice,
    ) {
    this.theModal = modalObj;
    this.notifier = notifierSrv;
  }

  ngOnInit(): void {
    this.loggedInfo = this.authenticationService.currentUserValue["data"];

    this.Cities = this.CitiesTemp = CityMarkers;
    this.Counties = CountyMarkers;
    this.Activities = ProviderActivities;
    this.Languages = Languages;
    this.ActivitiesType = AtivityType;
    this.OrderTargets = OrderTarget;
    this.Penalizacion = 0;

    if (this.loggedInfo.userGroup == "CLI") {
      this.readUserOffices();
      this.readProviders();
      this.readClientOrders();
    } else {
      //For providers
      this.readOrders();
     }

    this.orderForm = this.fb.group({
      providerActivity: ["", [Validators.required]],
      language: ["", [Validators.required]],
      office: ["", [Validators.required]],
      county: ["", [Validators.required]],
      city: ["", [Validators.required]],
      mode: ["", [Validators.required]],
      target: ["", [Validators.required]],
      date: ["", [Validators.required]],
      startTime: ["", [Validators.required]],
      endTime: ["", [Validators.required]],
      priceRangeStart: ["", [Validators.required]],
      priceRangeEnd: ["", [Validators.required]],
      rate: ["", []],
      pdfpath: ["",[]]
    });
  }

  get f() {
    return this.orderForm.controls;
  }

  public ShowInfoCancelOrder() {
    Swal.fire({
      //position: 'top-end',
      icon: "warning",
      title: "Orders cancelation",
      text: "",
      backdrop: false,
      showConfirmButton: true,
      confirmButtonColor: "#d33",

      confirmButtonText: "Close",
      //cancelButtonColor: "#d33",
      showCancelButton: false,
      cancelButtonText: "No",
      background: "#8fd",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      },
      position: "top-end",
      timer: 10000
    });
  }

  
  public calcHoras(order: any) {
    console.log(order);
    let diff = timeDiff(order.timeEnd, order.timeStart);
    if (diff != null && diff.horas == null) {
      diff.horas = 0;
    }
    console.log(diff);
    return diff;
  }

  public calcPenalizacion(order:any, tiempo: any, precio: any){
    var tiempoContratado = tiempo.difMin/60; //Minutos
    this.HContratadas = tiempoContratado;
    var timeActual = new Date();
    //Horas y Fecha de la orden
    var orderDate = new Date(new Date(order.date).toDateString() + " " + order.timeStart);
    
    //Rangos de Horarios
    if(tiempoContratado >= 0 && tiempoContratado <= 240  ){
      //2H antes
      this.PorcientoH = 50;
      this.HorasAntes = 2;
      this.timeHbefore = new Date(orderDate.setHours(orderDate.getHours() - 2));    
      if(timeActual > this.timeHbefore){
        console.log("Se paso de tiempo paga el 50%")
        this.Penalizacion = (tiempoContratado / 60) * (precio * (this.PorcientoH/100));
        console.log(this.Penalizacion)
        this.PenalizacionMsg = "You will be able to cancel up to 2 hours before: " +
              new Date(order.date).toDateString() + " " + order.timeStart +
              ".  The provider will be able to cancel up to 2 hours before: " +
              new Date(order.date).toDateString() + " " + order.timeStart +
              "... After that, you will have to pay for this cancelation $" + Math.round(this.Penalizacion);
      }
      else{
        this.notifier.notify(
          "info",
          "Order has been canceled without penalization"
        );
      }   
    }
    else if(tiempoContratado > 240 && tiempoContratado <= 480  ){
      //4H antes
      this.PorcientoH = 40;
      this.HorasAntes = 4;
      this.timeHbefore = new Date(orderDate.setHours(orderDate.getHours() - 4)); 
      if(timeActual > this.timeHbefore){
        console.log("Se paso de tiempo paga el 40%")
        this.Penalizacion = (tiempoContratado / 60) * (precio * this.PorcientoH);
            this.PenalizacionMsg = "You will be able to cancel up to 2 hours before: " +
            new Date(order.date).toDateString() + " " + order.timeStart +
            ".  The provider will be able to cancel up to 4 hours before: " +
            new Date(order.date).toDateString() + " " + order.timeStart +
            "... After that, you will have to pay for this cancelation $" + Math.round(this.Penalizacion);
      }
      else{
        this.notifier.notify(
          "info",
          "Order has been canceled without penalization"
        );
      }
    }
    else if(tiempoContratado > 480 && tiempoContratado <= 1140  ){
      //8H antes
      this.PorcientoH = 30;
      this.HorasAntes = 8;
      this.timeHbefore = new Date(orderDate.setHours(orderDate.getHours() - 8)); 
      if(timeActual > this.timeHbefore){
        console.log("Se paso de tiempo paga el 30%")
        this.Penalizacion = (tiempoContratado / 60) * (precio * this.PorcientoH);
        this.PenalizacionMsg = "You will be able to cancel up to 8 hours before: " +
          new Date(order.date).toDateString() + " " + order.timeStart +
          ".  The provider will be able to cancel up to 2 hours before: " +
          new Date(order.date).toDateString() + " " + order.timeStart +
          "... After that, you will have to pay for this cancelation $" + Math.round(this.Penalizacion);
      }
      else{
        this.notifier.notify(
          "info",
          "Order has been canceled without penalization"
        );
      }
    }
    else if(tiempoContratado > 1140){
      //12H antes
      this.PorcientoH = 20;
      this.HorasAntes = 12;
      this.timeHbefore = new Date(orderDate.setHours(orderDate.getHours() - 12)); 
      if(timeActual > this.timeHbefore){
        console.log("Se paso de tiempo paga el 20%")
        this.Penalizacion = (tiempoContratado / 60) * (precio * this.PorcientoH);
        this.PenalizacionMsg = "You will be able to cancel up to 12 hours before: " +
          new Date(order.date).toDateString() + " " + order.timeStart +
          ".  The provider will be able to cancel up to 2 hours before: " +
          new Date(order.date).toDateString() + " " + order.timeStart +
          "... After that, you will have to pay for this cancelation $" + Math.round(this.Penalizacion);
      }
      else{
        this.notifier.notify(
          "info",
          "Order has been canceled without penalization"
        );
      }
    }
  }
  
  public CancelOrder(order: any) {
    if (order.status == "BLOCKED") {
      this.notifier.notify(
        "info",
        "This order has been  blocked by some provider"
      );
      return;
    } else if (order.status == "FREE") {
      Swal.fire({
        //position: 'top-end',
        icon: "warning",
        title: "Cancel Order",
        text: "Are you sure you want cancel this Order",
        backdrop: false,
        showConfirmButton: true,
        confirmButtonColor: "#d33",

        confirmButtonText: "Yes, Cancel Order",
        //cancelButtonColor: "#d33",
        showCancelButton: true,
        cancelButtonText: "No",
        background: "#8fd",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        },
        position: "top-end"
        // timer: 10000
      }).then(result => {
        if (result.isConfirmed) {
          this.usrSrv
            .updateOrderStatus(order.orderId, "CANCELED")
            .subscribe(resp => {
              this.notifier.notify("info", "Order was canceled");
              //Send Notification provider
              //Generate Registro de cancelacion
              var data = {
                orderId: order.orderId,
                clientId: order.clientId, 
                horasAntes:0, 	
                porciento:0, 
                monto:0, 
                status:'FREE',
              }
              this.usrSrv.createCanceledOrder(data)
              this.readClientOrders();
            });
        }
      });
      return;
    } else if (order.status == "ACCEPTED") {
      //this.notifier.notify("info", "This order has been  taken by some provider");
      this.usrSrv.getProcedingByOrder(order).subscribe(response => {
        if (response.data) {
          this.ProviderActivityData = response.data; 
          this.calcPenalizacion(order, this.calcHoras(order), this.ProviderActivityData[0].price);
          Swal.fire({
            //position: 'top-end',
            icon: "warning",
            title: "Cancel Order",
            text:this.PenalizacionMsg,
            backdrop: false,
            showConfirmButton: true,
            confirmButtonColor: "#d33",

            confirmButtonText: "Yes, Cancel Order",
            //cancelButtonColor: "#d33",
            showCancelButton: true,
            cancelButtonText: "No",
            background: "#8fd",
            showClass: {
              popup: "animate__animated animate__fadeInDown"
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp"
            },
            position: "top-end"
            // timer: 10000
          }).then(result => {
            //console.log(result)
            if (result.isConfirmed) {
              this.usrSrv
                .updateOrderStatus(order.orderId, "CANCELED")
                .subscribe(resp => {
                  
                  //Send Notification provider
                  //Generate Registro de cancelacion
                  var data = {
                    orderId: order.orderId,
                    clientId: order.clientId, 
                    horasAntes:this.HorasAntes, 	
                    porciento:this.PorcientoH, 
                    monto:Math.round(this.Penalizacion), 
                    status:'ACCEPTED',
                    date: new Date()
                  }
                  this.usrSrv.createCanceledOrder(data).subscribe(r=>{
                    this.readClientOrders();
                    this.notifier.notify("info", "Order was canceled");
                  })
                });
            }
          });
        }
        else{
          this.notifier.notify(
            "error",
            "Order could not be canceled"
          );
        }
      });
    }
  }

  public RemoveOrder(order: any) {
    Swal.fire({
      icon: "question",
      title: "Remove Order",
      text: "Are you sure you want remove this Order",
      backdrop: true,
      showConfirmButton: true,
      confirmButtonColor: "#d33",

      confirmButtonText: "Yes, remove",
      //cancelButtonColor: "#d33",
      showCancelButton: true,
      cancelButtonText: "No",
      background: "#8fd",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
      position: "top-end",
      // timer: 10000
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return;
  } 


  getExtension(filename): string {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
  }

  fileChangeEvent(): void {
    const fr: File = this.event.target.files[0];
    const ext = this.getExtension(this.event.target.files[0].name);
    var token = new Date().getTime().toString();
    this.filepdf = "order_spec_" + token +  "." + ext;
    const f = new File([fr.slice(0, fr.size, fr.type)], this.filepdf , {type: fr.type});
    this.UploadDocument(f); 
  }

  public setEvent(event: any){
    this.event = event;
  }

  public uploadPdfFromList(event:any, order:any){
    this.setEvent(event);
    this.fileChangeEvent();
    //Update order pdfpath
  
    this.usrSrv.updateOrderPdfPath(order.orderId, this.filepdf).subscribe(resp => {
      //console.log(resp)
      //this.notifier.notify("info", "File uploaded");
    });
  }

  public UploadDocument(file: any){
      this.inProgress = true;
      this.sub = this.gralSrv.uploadFileProgress(PROFILE_DOC, file).pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.fileProgress = Math.round(event.loaded * 100 / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.inProgress = false;
          this.notifier.notify('error', 'Error uploading file...');
          return of(`Carga de archivo: fallida.`);
        })).subscribe(
          (event: any) => {
            if (typeof (event) === 'object') {
              this.notifier.notify('info', 'PDF uploaded');
              this.completed = true;
              this.inProgress = false;
              this.fileName = file.name;
            }
          },
          (error) => console.log(error),
          () => console.log('completado')
        );
    }


    public downloadFileProgress(filename:any){
      // var f = this.gralSrv.downloadFileProgress(filename);
      // f.subscribe(r =>{
        
      // })
    } 

  public CloseOrder(order: any){
    
  }

  public acceptTermsOrder(order: any, tiempo:any){
    var tiempoContratado = tiempo.difMin; //Minutos
    this.HContratadas = tiempoContratado;
    var timeActual = new Date();
    
    //Horas y Fecha de la orden
    var orderDate = new Date(new Date(order.date).toDateString() + " " + order.timeStart);
    
    //Rangos de Horarios
    if(tiempoContratado >= 0 && tiempoContratado <= 240  ){
      //2H antes
      this.PorcientoH = 50;
      this.HorasAntes = 2;
      //alert(this.HorasAntes)
      this.timeHbefore = new Date(orderDate.setHours(orderDate.getHours() - this.HorasAntes));    
      if(timeActual > this.timeHbefore){
        this.PenalizacionMsg = "You will be able to cancel up to " + this.HorasAntes + " hours before: " +
                new Date(order.date).toDateString() + " " + order.timeStart +
                ".  The provider will be able to cancel up to " + this.HorasAntes + " hours before: " +
                new Date(order.date).toDateString() + " " + order.timeStart +
                "... After that, you will have to pay for this cancelation " +  this.PorcientoH + "% of price per hour";
      }
    }
    else if(tiempoContratado > 240 && tiempoContratado <= 480){
        //4H antes
        this.PorcientoH = 40;
        this.HorasAntes = 4;
        //alert(this.HorasAntes)
        this.timeHbefore = new Date(orderDate.setHours(orderDate.getHours() - this.HorasAntes));    
        if(timeActual > this.timeHbefore){
          this.PenalizacionMsg = "You will be able to cancel up to " + this.HorasAntes + " hours before: " +
                new Date(order.date).toDateString() + " " + order.timeStart +
                ".  The provider will be able to cancel up to " + this.HorasAntes + " hours before: " +
                new Date(order.date).toDateString() + " " + order.timeStart +
                "... After that, you will have to pay for this cancelation " +  this.PorcientoH + "% of price per hour";
      }
    }
    else  if(tiempoContratado > 480 && tiempoContratado <= 1140){
        //8H antes
        this.PorcientoH = 30;
        this.HorasAntes = 8;
       // alert(this.HorasAntes)

        this.timeHbefore = new Date(orderDate.setHours(orderDate.getHours() - this.HorasAntes));    
        if(timeActual > this.timeHbefore){
          this.PenalizacionMsg = "You will be able to cancel up to " + this.HorasAntes + " hours before: " +
                new Date(order.date).toDateString() + " " + order.timeStart +
                ".  The provider will be able to cancel up to " + this.HorasAntes + " hours before: " +
                new Date(order.date).toDateString() + " " + order.timeStart +
                "... After that, you will have to pay for this cancelation " +  this.PorcientoH + "% of price per hour";
      }
    }
     else if(tiempoContratado > 1140){
        //12H antes
        this.PorcientoH = 20;
        this.HorasAntes = 12;
        //alert(this.HorasAntes)
        this.timeHbefore = new Date(orderDate.setHours(orderDate.getHours() - this.HorasAntes));    
        if(timeActual > this.timeHbefore){
          this.PenalizacionMsg = "You will be able to cancel up to " + this.HorasAntes + " hours before: " +
                new Date(order.date).toDateString() + " " + order.timeStart +
                ".  The provider will be able to cancel up to " + this.HorasAntes + " hours before: " +
                new Date(order.date).toDateString() + " " + order.timeStart +
                "... After that, you will have to pay for this cancelation " +  this.PorcientoH + "% of price per hour";
      }
    }
  }
  
  onSubmit() { 
    this.loading = true;
    var frmData = {
      clientId: this.loggedInfo.systemaccountId,
      countyId: this.f.county.value.Id,
      county: this.f.county.value.county,
      cityId: this.f.city.value.Id,
      city: this.f.city.value.city,
      date: this.f.date.value,
      status: "FREE",
      timeStart: this.f.startTime.value,
      timeEnd: this.f.endTime.value,
      language: this.f.language.value.name,
      languageId: this.f.language.value.languageId,
      languageCode: this.f.language.value.code,
      priceRangeStart: this.f.priceRangeStart.value,
      priceRangeEnd: this.f.priceRangeEnd.value,
      placeName: this.f.office.value.placeName,
      address: this.f.office.value.placeAddress,
      activityId: this.f.providerActivity.value.id,
      activity: this.f.providerActivity.value.type,
      latitude: this.f.office.value.latitude,
      longitude: this.f.office.value.longitude,
      orderType: this.f.mode.value.type,
      rate: this.f.rate.value,
      targetId: this.f.target.value.id,
      target: this.f.target.value.name,
      pdfpath: this.f.target.value.pdfpath
    };
    this.acceptTermsOrder(frmData, this.calcHoras(frmData));

    Swal.fire({
      icon: "info",
      title: "Accept orders Terms",
      text: this.PenalizacionMsg,
      backdrop: false,
      showConfirmButton: true,
      //confirmButtonColor: '#d33',
      cancelButtonColor: "#d33",
      showCancelButton: true,
      cancelButtonText: "Not Agree",
      confirmButtonText: "Agree",
      background: "#8fd",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      },
      position: "top-end",
      //timer: 6000
    }).then(result => {
      if (result.isConfirmed) {

        this.fileChangeEvent();
        
        frmData.pdfpath = this.filepdf;
        this.usrSrv.createOrder(frmData).subscribe(
          data => {
            if (data.success) {
              this.notifier.notify("success", data.message);
              this.orderForm.reset();
              this.mostrar = true;
            } else {
              this.notifier.notify("error", data.message);
            }
            this.readClientOrders();
            this.loading = false;
          },
          error => {
            console.log(error);
            this.error = error;
            this.loading = false;
            this.notifier.notify("error", error.message);
          }
        );
      }
    });
  }

  filterModal(content) {
    this.theModal
      .open(content, {
        windowClass: "modal-large",
        size: "lg",
        backdrop: true,
        centered: true
      })
      .result.then(
        result => {
          //alert(`Closed with: ${result}`);
        },
        reason => {
          //alert(`Dismissed ${this.getDismissReason(reason)}`);
        }
      );
  }

  public readProviders() {
    this.usrSrv.getProviders().subscribe(response => {
      this.providers = response.data;
    });
  }


  public readClientOrders() {
    this.usrSrv.getOrderByUser(this.loggedInfo).subscribe(response => {
      this.orders = response.data.filter(x=>x.status !== "CANCELED");
      console.log(this.orders);
    });
  }

  public readOrders() {
    this.usrSrv.getOrders().subscribe(response => {
      this.providerOrders = response.data;
      console.log(this.orders);
    });
  }

  public readUserOffices() {
    this.usrSrv
      .getClientOfficeByUser(this.loggedInfo.systemaccountId)
      .subscribe(response => {
        this.Offices = response.data;
      });
  }

  public CustomizeOrder() {
    if (this.loggedInfo.membre == false) {
      Swal.fire({
        //position: 'top-end',
        icon: "warning",
        title: "Not Access",
        text: "Get membership to access this option",
        backdrop: false,
        showConfirmButton: true,
        //confirmButtonColor: '#d33',
        cancelButtonColor: "#d33",
        showCancelButton: true,
        cancelButtonText: "Get membership",
        background: "#8fd",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        },
        position: "top-end",
        timer: 6000
      }).then(result => {
        //this.closeThis();
        //this.router.navigate(['/login']);
      });
      return;
    }
    this.customize = !this.customize;
  }

  public closeModal() {
    this.modalObj.dismissAll();
  }

  onChange() {
    var data = this.CitiesTemp.filter(it => {
      return it.county.includes(this.selected.county);
    });
    this.Cities = data;
  }

  SelectedOffice() {
    //console.log(this.OfficeSelected);
  }
  SelectedActivity() {
    //console.log(this.ActivitySelected);
  }
  SelectedLanguage() {
    //console.log(this.LanguageSelected);
  }
  SelectedMode() {
    //console.log(this.ModeSelected);
  }
  SelectedTarget() {
    //console.log(this.TargetSelected);
  }
}
